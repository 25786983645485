import './autocomplete.scss'

import CustomHTMLElement from 'html-element'
import { sendRequest } from 'js-api'

import SpriteSvg from 'elements/svg'

const HIGHLIGHTED_CLASS = 'search-autocomplete__completion--highlighted'

export default class SearchAutocomplete extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.api = this.getAttribute('data-api')

    this.setupElements()
  }

  setupElements() {
    // Input
    this.input = document.createElement('input')
    this.input.type = 'text'
    this.input.name = 'query'
    this.input.placeholder = 'Search'
    this.input.spellcheck = false

    this.appendChild(this.input)

    this.input.addEventListener('focus', this.inputFocused.bind(this))
    this.input.addEventListener('blur', this.inputBlurred.bind(this))
    this.input.addEventListener('input', this.inputChanged.bind(this))
    this.input.addEventListener('keydown', this.keyPressed.bind(this))

    // Icon
    this.icon = new SpriteSvg()
    this.icon.iconName = 'icon_magnifying_glass'
    this.icon.addEventListener('click', this.iconClicked.bind(this))
    this.appendChild(this.icon)

    // Completions
    this.completionsElement = document.createElement('div')
    this.completionsElement.classList.add('search-autocomplete__completions')
    this.appendChild(this.completionsElement)
  }

  ////////// Completions

  updateCompletions() {
    if (this.updateTimer) {
      window.clearTimeout(this.updateTimer)
      this.updateTimer = null
    }

    let query = this.input.value
    if (typeof query !== 'string' || query.length === 0) {
      this.completionsElement.innerHTML = ''
      return
    }

    sendRequest('GET', this.api, {
      params: { query: query },
      headers: { 'X-Application-Layout': 'injection' }
    }).then((response) => {
      this.completionsElement.innerHTML = ''

      let body = response.content.querySelector('body')
      let children = body.childNodes
      for (let i = children.length; i > 0; i--) {
        let child = children[i - 1]
        this.completionsElement.insertBefore(child, this.completionsElement.firstChild)
      }
    })
  }

  updateIcon() {
    let query = this.input.value
    if (typeof query !== 'string' || query.length === 0) {
      this.icon.iconName = 'icon_magnifying_glass'
    } else {
      this.icon.iconName = 'icon_cross'
    }
  }

  clearCompletions() {
    this.input.value = ''
    this.updateCompletions()
    this.updateIcon()
  }

  ////////// Highlight

  get highlightedCompletion() {
    return this.completionsElement.querySelector(`.${HIGHLIGHTED_CLASS}`)
  }

  clearHighlightedCompletion() {
    let completion = this.highlightedCompletion
    if (completion) {
      completion.classList.remove(HIGHLIGHTED_CLASS)
    }
  }

  highlightPreviousCompletion() {
    let completion = this.highlightedCompletion
    if (completion) {
      if (completion.previousSibling) {
        completion.classList.remove(HIGHLIGHTED_CLASS)
        completion.previousSibling.classList.add(HIGHLIGHTED_CLASS)
      }
    } else {
      this.completionsElement.lastChild.classList.add(HIGHLIGHTED_CLASS)
    }
  }
  highlightNextCompletion() {
    let completion = this.highlightedCompletion
    if (completion) {
      if (completion.nextSibling) {
        completion.classList.remove(HIGHLIGHTED_CLASS)
        completion.nextSibling.classList.add(HIGHLIGHTED_CLASS)
      }
    } else {
      this.completionsElement.firstChild.classList.add(HIGHLIGHTED_CLASS)
    }
  }

  selectCompletion() {
    let completion = this.highlightedCompletion
    if (!completion || completion.tagName === 'BUTTON') {
      this.closest('form').submit()
    } else {
      let url = completion.href
      window.Turbolinks.visit(url)
    }
  }

  ////////// Events

  inputFocused(_event) {
    this.classList.add('search-autocomplete--active')
  }
  inputBlurred(_event) {
    setTimeout(() => this.classList.remove('search-autocomplete--active'), 500)
  }

  inputChanged(_event) {
    this.updateIcon()

    if (this.updateTimer) {
      return
    }
    this.updateTimer = window.setTimeout(this.updateCompletions.bind(this), 200)
    this.clearHighlightedCompletion()
  }

  iconClicked(_event) {
    this.clearCompletions()
    this.input.focus()
  }

  keyPressed(event) {
    if (event.metaKey || event.ctrlKey) { return }

    switch (event.keyCode) {
      case 13: // Return
        this.selectCompletion()
        break
      case 27:
        this.clearCompletions()
        break
      case 38: // Up
        this.highlightPreviousCompletion()
        break
      case 40: // Down
        this.highlightNextCompletion()
        break
      default:
        return
    }

    event.preventDefault()
    return false
  }

}
window.customElements.define('search-autocomplete', SearchAutocomplete)
