import './box.scss'

let raceBoxes = []
let updateInterval = null
let intervalSeconds = false

const RELATIVE_TIME_THRESHOLD = (1000 * 60 * 30) // 30 min
const TIME_CLOSE_THRESHOLD = (1000 * 60 * 15) // 15 min
const SECONDS_THRESHOLD = (1000 * 60 * 2.5) // 2.5 min

const UPDATE_INTERVAL = (1000 * 10) // 1 min
const SECONDS_UPDATE_INTERVAL = 1000 // 1 sec

document.addEventListener('turboujs:load', (_event) => {
  clearUpdateInterval()
  intervalSeconds = false

  raceBoxes = document.querySelectorAll('[data-race-box]')
  if (raceBoxes.length > 0) {
    raceBoxes.forEach((raceBox) => {
      raceBox.date = new Date(raceBox.getAttribute('data-race-box'))
      raceBox.formattedTime = raceBox.querySelector('formatted-time')
    })

    updateRaceBoxes()
    startUpdateInterval()
  }
})

function updateRaceBoxes() {
  let now = new Date().getTime()

  raceBoxes.forEach((raceBox) => {
    let difference = raceBox.date.getTime() - now

    if (raceBox.formattedTime) {
      if (difference < RELATIVE_TIME_THRESHOLD) {
        raceBox.formattedTime.format = 'relative-short'
      } else {
        raceBox.formattedTime.format = 'time_24'
      }
      raceBox.formattedTime.date = raceBox.date
    }

    if (difference < TIME_CLOSE_THRESHOLD) {
      raceBox.classList.add('race-box--time-close')
    }
    if (difference < SECONDS_THRESHOLD) {
      raceBox.classList.add('race-box--time-seconds')
      intervalSeconds = true
    }
  })
}

function startUpdateInterval() {
  clearUpdateInterval()

  if (intervalSeconds) {
    updateInterval = window.setInterval(() => {
      updateRaceBoxes()
    }, SECONDS_UPDATE_INTERVAL)
  } else {
    updateInterval = window.setInterval(() => {
      updateRaceBoxes()

      if (intervalSeconds) {
        startUpdateInterval()
      }
    }, UPDATE_INTERVAL)
  }
}
function clearUpdateInterval() {
  if (updateInterval !== null) {
    window.clearInterval(updateInterval)
    updateInterval = null
  }
}
