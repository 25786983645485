import './tutorial.scss'

import CustomHTMLElement from 'html-element'

import { wait } from 'components/async'
import CreateButton from 'elements/button'

//////////

let tutorialData = window.localStorage.getItem('grnsw-tutorials')
if (tutorialData) {
  try {
    tutorialData = JSON.parse(tutorialData)
  } catch {}
}
tutorialData = tutorialData || {}
tutorialData.shownPrompts = tutorialData.shownPrompts || {}

document.addEventListener('turboujs:load', (_event) => {
  findAndDisplayTutorials(document)
})

function findAndDisplayTutorials(element) {
  let now = (new Date()).toISOString()

  let tutorialElements = Array.from(document.querySelectorAll('[data-tutorial]'))
  tutorialElements = tutorialElements
    .filter(te => {
      if (te.offsetParent === null) { return false }

      let promptId = te.getAttribute('data-tutorial')
      if (tutorialData.shownPrompts[promptId]) { return false }

      tutorialData.shownPrompts[promptId] = {
        at: now
      }

      return true
    })

  if (tutorialElements.length === 0) { return }

  window.localStorage.setItem('grnsw-tutorials', JSON.stringify(tutorialData))

  let overlayElement = new TutorialOverlay(tutorialElements)
  document.body.appendChild(overlayElement)
  overlayElement.showNextElement()
}

//////////

class TutorialOverlay extends CustomHTMLElement {

  _runSetup(elements) {
    super._runSetup()

    this.tutorialElements = elements
    this.elementIndex = -1

    this.promptElement = new TutorialPrompt()
    this.appendChild(this.promptElement)

    this.promptElement.nextButton.addEventListener('click', this.showNextElement.bind(this))
    this.promptElement.skipButton.addEventListener('click', this.skipTutorial.bind(this))
  }

  _runConnected() {
    this.addDocumentBoundEventListener('scrolling-retainer:releasing', (event) => {
      if (this.isShowing) {
        event.preventDefault()
        return false
      }
    })
  }

  disconnectedCallback() {
    this.removeDocumentBoundEventListener('scrolling-retainer:releasing')
  }

  //////////

  async showNextElement() {
    this.elementIndex += 1
    let element = this.tutorialElements[this.elementIndex]

    if (element) {
      await this.captureElement(element)
    } else {
      await this.hide()
      this.remove()
    }
  }

  async skipTutorial() {
    await this.hide()
    this.remove()
  }

  async captureElement(element) {
    let html = document.body.parentElement
    let htmlCenter = html.scrollHeight / 2
    let elementBounds = element.getBoundingClientRect()
    let elementCenter = elementBounds.top + (elementBounds.width / 2)

    let scrollX = html.scrollLeft
    let scrollY = html.scrollTop + elementCenter - htmlCenter

    if (scrollY < 0) {
      scrollY = 0
    } else if (scrollY > html.scrollHeight - window.innerHeight) {
      scrollY = html.scrollHeight - window.innerHeight
    }

    if (scrollX !== html.scrollLeft || scrollY !== html.scrollTop) {
      html.scroll(scrollX, scrollY, { animated: true })
      await wait(0.3)
    }

    elementBounds = element.getBoundingClientRect()

    let top = elementBounds.top + 'px'
    let bottom = elementBounds.top + elementBounds.height + 'px'
    let left = elementBounds.left + 'px'
    let right = elementBounds.left + elementBounds.width + 'px'

    this.style.clipPath = `polygon(
      0% 0%, 0% 100%, 100% 100%, 100% 0%, 0% 0%,
      ${left} ${top}, ${right} ${top}, ${right} ${bottom}, ${left} ${bottom}, ${left} ${top}
    )`

    this.promptElement.title = element.getAttribute('data-tutorial-title')
    this.promptElement.text = element.getAttribute('data-tutorial-text')
    this.promptElement.counter = `${this.elementIndex + 1}/${this.tutorialElements.length}`

    if (this.elementIndex + 1 === this.tutorialElements.length) {
      this.promptElement.nextButton.querySelector('span').innerText = 'Done'
      this.promptElement.skipButton.remove()
    }

    let elementSize = Math.min(window.innerWidth / 2, 300)

    this.promptElement.style.top = bottom
    if (elementBounds.left + (elementSize / 2) > (window.innerWidth / 2)) {
      this.promptElement.style.left = elementBounds.left + elementBounds.width - elementSize + 'px'
      this.promptElement.classList.remove('tutorial-prompt--left')
      this.promptElement.classList.add('tutorial-prompt--right')
    } else {
      this.promptElement.style.left = left
      this.promptElement.classList.add('tutorial-prompt--left')
      this.promptElement.classList.remove('tutorial-prompt--right')
    }

    if (!this.isShowing) {
      await this.show()
    }
  }

  ////////// State

  get isShowing() {
    return this.classList.contains('tutorial-overlay--active')
  }

  async show() {
    this.retainScrolling()

    await wait()
    this.classList.add('tutorial-overlay--active')
    await wait(0.3)
  }

  async hide() {
    this.releaseScrolling()

    this.classList.remove('tutorial-overlay--active')

    await wait(0.3)
  }
}
window.customElements.define('tutorial-overlay', TutorialOverlay)

//////////

class TutorialPrompt extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.createElements()
  }

  //////////

  get title() {
    return this.titleElement.innerText
  }
  set title(newValue) {
    this.titleElement.innerText = newValue
  }

  get text() {
    return this.textElement.innerText
  }
  set text(newValue) {
    this.textElement.innerText = newValue
  }

  get counter() {
    return this.counterElement.innerText
  }
  set counter(newValue) {
    this.counterElement.innerText = newValue
  }

  createElements() {
    this.titleElement = document.createElement('div')
    this.titleElement.classList.add('tutorial-prompt__title')
    this.appendChild(this.titleElement)

    this.textElement = document.createElement('div')
    this.textElement.classList.add('tutorial-prompt__text')
    this.appendChild(this.textElement)

    let footerElement = document.createElement('div')
    footerElement.classList.add('tutorial-prompt__footer')
    this.appendChild(footerElement)

    this.nextButton = CreateButton({
      text: 'Next',
      size: 'small',
      color: 'brand-dark'
    })
    footerElement.appendChild(this.nextButton)

    this.skipButton = CreateButton({
      text: 'Skip',
      size: 'small',
      color: 'brand-dark',
      style: 'outline'
    })
    footerElement.appendChild(this.skipButton)

    this.counterElement = document.createElement('div')
    this.counterElement.classList.add('tutorial-prompt__footer__counter')
    footerElement.appendChild(this.counterElement)
  }

}
window.customElements.define('tutorial-prompt', TutorialPrompt)
