document.addEventListener('turboujs:load', () => {
  document.querySelectorAll('.premiership-filter-form form select').forEach((element) => {
    element.addEventListener('change', selectChanged)
  })
})

function selectChanged() {
  const form = this.closest('form')
  const formData = new FormData(form)
  const params = new URLSearchParams()

  // Sort the form data by key
  Array.from(formData.entries()).sort((a, b) => a[0].localeCompare(b[0])).forEach(([key, value]) => {
    params.append(key, value)
  })

  // Redirect to the new URL with sorted query params
  const url = new URL(window.location)
  url.search = params.toString()
  window.location = url.toString()
}
