import './badge.scss'

HTMLElement.prototype.setBadge = function(text, options = {}) {
  let badge = null
  if (this.classList.contains('badge')) {
    badge = this
  } else {
    badge = this.querySelector('.badge')
  }

  if (!badge) {
    this.classList.add('badge-wrapper')

    badge = document.createElement('div')
    this.appendChild(badge)
  }

  badge.className = ''
  badge.classList.add('badge')
  if (options.color) {
    badge.classList.add(`badge--color-${options.color}`)
  }

  badge.innerText = text
}
