import './controls.scss'

import SpriteSvg from 'elements/svg'

import VideoOverlay from './overlay'
import VideoControlsScrubber from './scrubber'

export default class VideoControls extends VideoOverlay {

  constructor(player) {
    super()

    this.player = player

    this.createElements()
  }

  ////////// Elements

  createElements() {
    this.playbackButton = this.createButton('video_play', (_event) => this.playbackClicked())

    this.progressText = document.createElement('div')
    this.progressText.classList.add('video-controls__text')
    this.appendChild(this.progressText)

    this.loadingIndicator = this.createButton('icon_loading')

    this.progressScrubber = new VideoControlsScrubber()
    this.progressScrubber.hide()
    this.appendChild(this.progressScrubber)
    this.progressScrubber.addEventListener('beginChangingValue', (event) => this.beginScrubbingProgress(event))
    this.progressScrubber.addEventListener('valueChanged', (event) => this.scrubProgress(event))
    this.progressScrubber.addEventListener('endChangingValue', (event) => this.endScrubbingProgress(event))

    this.volumeButton = this.createButton('video_volume_low', (_event) => this.volumeClicked())
    this.volumeButton.show()
    this.airplayButton = this.createButton('video_airplay', (_event) => this.airplayClicked())
    this.pipButton = this.createButton('video_pip_enter', (_event) => this.pipClicked())
    this.fullscreenButton = this.createButton('video_fullscreen_enter', (_event) => this.fullscreenClicked())
  }

  createButton(iconName, action) {
    let svg = new SpriteSvg()
    svg.iconName = iconName

    let button
    if (action) {
      button = document.createElement('a')
      button.addEventListener('click', action)
    } else {
      button = document.createElement('div')
    }

    button.classList.add('video-controls__button')
    button.svg = svg
    button.appendChild(svg)

    button.setIcon = function(name) { this.svg.iconName = name }
    button.show = function() { this.style.display = '' }
    button.hide = function() { this.style.display = 'none' }

    button.hide()
    this.appendChild(button)

    return button
  }

  playbackClicked() {
    this.player.togglePlayback()
  }

  beginScrubbingProgress(event) {
    this._scrubbingWasPlaying = this.player.isPlaying
    this.player.pausePlayback()
  }
  scrubProgress(event) {
    this.player.progress = this.progressScrubber.value
  }
  endScrubbingProgress(event) {
    if (this._scrubbingWasPlaying) {
      this.player.resumePlayback()
    }
    this.player.progress = this.progressScrubber.value
  }

  volumeClicked() {
    this.player.toggleSound()
  }

  pipClicked() {
    this.player.togglePip()
  }

  fullscreenClicked() {
    if (this.player.usingFullscreeen) {
      this.player.exitFullscreen()
    } else {
      this.player.enterFullscreen()
    }
  }

  airplayClicked() {
    this.player.showAirplayPicker()
  }

  ////////// State

  loadingStateChanged() {
    if (this.player.isLoading) {
      this.loadingIndicator.show()
    } else {
      this.loadingIndicator.hide()
    }
  }

  playbackStateChanged() {
    this.playbackButton.show()

    if (this.player.isPlaying) {
      this.playbackButton.setIcon('video_pause')
    } else {
      this.playbackButton.setIcon('video_play')
    }
  }

  progressStateChanged() {
    if (this.player.isLiveVideo) {
      this.progressText.innerText = 'Live'
    } else {
      let elapsed = this.player.elapsed.toDurationString()
      let duration = this.player.duration.toDurationString()
      this.progressText.innerText = `${elapsed} / ${duration}`

      if (!this.player.playingPreroll) {
        this.progressScrubber.show()
        this.progressScrubber.value = this.player.progress
      }
    }
  }

  volumeStateChanged() {
    if (this.player.playingPreroll) {
      this.volumeButton.hide()
    } else {
      this.volumeButton.show()
    }

    if (this.player.hasSound) {
      this.volumeButton.setIcon('video_volume_loud')
    } else {
      this.volumeButton.setIcon('video_volume_muted')
    }
  }

  presentationStateChanged() {
    switch (this.player.presentationMode) {
      case 'inline':
        if (this.player.supportsPip) {
          this.pipButton.show()
          this.pipButton.setIcon('video_pip_enter')
        }

        if (this.player.supportsFullscreen) {
          this.fullscreenButton.show()
          this.fullscreenButton.setIcon('video_fullscreen_enter')
        }
        break
      case 'fullscreen':
        this.pipButton.hide()

        this.fullscreenButton.show()
        this.fullscreenButton.setIcon('video_fullscreen_exit')
        break
      case 'pip':
        this.pipButton.show()
        this.pipButton.setIcon('video_pip_exit')

        this.fullscreenButton.hide()
        break
    }
  }

  airplayStateChanged() {
    if (this.player.supportsAirplay && this.player.canAirplay) {
      this.airplayButton.show()
    } else {
      this.airplayButton.hide()
    }
  }

}

window.customElements.define('video-controls', VideoControls)
