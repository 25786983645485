import SpriteSvg from './svg'

export default function CreateButton(options) {
  let tagName = options.tag || 'a'
  let button = document.createElement(tagName)
  button.classList.add('button')

  if (options.style) {
    button.classList.add(`button--style-${options.style}`)
  }
  if (options.color) {
    button.classList.add(`button--color-${options.color}`)
  }
  if (options.size) {
    button.classList.add(`button--size-${options.size}`)
  }

  if (options.icon) {
    let icon = new SpriteSvg()
    icon.iconName = options.icon
    button.appendChild(icon)
  }
  if (options.text) {
    let text = document.createElement('span')
    text.innerText = options.text
    button.appendChild(text)
  }

  if (options.title) {
    button.title = options.title
  }

  if (options.type) {
    button.type = options.type
  }
  if (options.value) {
    button.value = options.value
  }

  return button
}
