import './odd.scss'

import CustomHTMLElement from 'html-element'

import runnerOddsApi from 'networking/api/runners/odds'
import SpriteSvg from 'elements/svg'

const ODDS_UPDATE_INTERVAL = 60 * 1000

export default class RunnerOdd extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.raceId = this.getIntegerAttribute('data-race-id')
    this.runnerId = this.getIntegerAttribute('data-runner-id')
    this.future = this.getBooleanAttribute('data-future')
    this.marketCode = this.getAttribute('data-market')

    if (this.raceId) {
      this.mode = 'race'
    } else if (this.runnerId) {
      this.mode = 'runner'
    }

    this.setupElements()

    this.addEventListener('click', this.clicked)

    updateOddsWhenReady()
  }

  setupElements() {
    if (this.mode === 'race') {
      this.rugIcon = new SpriteSvg()
      this.appendChild(this.rugIcon)
    }

    this.bookmakerLogo = new SpriteSvg()
    this.bookmakerLogo.classList.add('runner-odd__bookmaker')
    this.appendChild(this.bookmakerLogo)

    this.priceElement = document.createElement('span')
    this.priceElement.classList.add('runner-odd__price')
    this.appendChild(this.priceElement)
  }

  ////////// Odds

  get currentOdds() {
    return this._currentOdds || []
  }
  set currentOdds(odds) {
    this._currentOdds = odds

    this.displayOdds()
  }

  marketOdds(marketCode) {
    return this.currentOdds.filter((odd) => odd.market.code === marketCode)
  }

  ////////// Display

  displayOdds() {
    let odds = this.marketOdds(this.marketCode)

    let odd = null
    if (odds && odds.length > 0) {
      let preferredBookmakerId = odds.map((pb) => pb.preferred_bookmaker_id).filter(x => x !== null)
      let preferredOdd = odds.filter((o) => o.bookmaker.id === preferredBookmakerId[0])
      odd = preferredOdd[0]
    }
    if (odd == null) {
      this.bookmakerLogo.iconName = null
      this.priceElement.innerText = 'N/A'
    } else if (odd.price == null || odd.price === 0) {
      this.displayOdd = odd
      this.bookmakerLogo.iconName = `bookmaker_${odd.bookmaker.code}_square`
      this.priceElement.innerText = 'N/A'
    } else {
      if (this.displayOdd) {
        if (this.displayOdd.price < odd.price) {
          this.displayIncrease()
        } else if (odd.price < this.displayOdd.price) {
          this.displayDecrease()
        }
      }

      this.displayOdd = odd
      this.priceElement.innerText = odd.price.toFixed(2)

      this.bookmakerLogo.iconName = `bookmaker_${odd.bookmaker.code}_square`

      if (this.rugIcon) {
        if (this.mode === 'race' && this.displayOdd.runner) {
          this.rugIcon.iconName = `rug_${this.displayOdd.runner.drawn_box}`
        } else {
          this.rugIcon.iconName = null
        }
      }
    }
  }

  displayDecrease() {
    this.classList.add('runner-odd--decreasing')
    setTimeout(() => this.classList.remove('runner-odd--decreasing'), 1000)
  }
  displayIncrease() {
    this.classList.add('runner-odd--increasing')
    setTimeout(() => this.classList.remove('runner-odd--increasing'), 1000)
  }

  //////////

  clicked(event) {
    event.stopPropagation()
    if (this.displayOdd && this.displayOdd.bookmaker.code !== 'wizardofodds') {
      window.open(`/api/runners/odds/${this.displayOdd.id}/redirect`, '_blank')
    }
  }

}
window.customElements.define('runner-odd', RunnerOdd)

////////// Updater

let oddsUpdateTimer = null
let updatingOdds = false

function updateOddsWhenReady() {
  if (oddsUpdateTimer) {
    window.clearTimeout(oddsUpdateTimer)
    oddsUpdateTimer = null
  }

  oddsUpdateTimer = window.setTimeout(updateOdds, 100)
}

function updateOdds() {
  if (updatingOdds) {
    return
  }
  updatingOdds = true

  if (oddsUpdateTimer) {
    oddsUpdateTimer = null
  }

  let oddsElements = Array.from(document.querySelectorAll('runner-odd'))
  if (oddsElements.length === 0) {
    return
  }

  let ids = {
    raceIds: oddsElements.filter((el) => el.mode === 'race' && !el.future).map((el) => el.raceId),
    futureRaceIds: oddsElements.filter((el) => el.mode === 'race' && el.future).map((el) => el.raceId),
    runnerIds: oddsElements.filter((el) => el.mode === 'runner' && !el.future).map((el) => el.runnerId),
    futureRunnerIds: oddsElements.filter((el) => el.mode === 'runner' && el.future).map((el) => el.runnerId)
  }

  runnerOddsApi.all(ids).then((response) => {
    oddsUpdateTimer = window.setTimeout(updateOdds, ODDS_UPDATE_INTERVAL)
    updatingOdds = false

    let races = response.content.race_odds
    let futureRaces = response.content.future_race_odds
    let runners = response.content.runner_odds
    let futureRunners = response.content.future_runner_odds

    oddsElements.forEach((element) => {
      let odds = null
      if (element.future) {
        switch (element.mode) {
          case 'race':
            if (futureRaces) {
              odds = futureRaces[element.raceId] || null
            } else {
              odds = null
            }
            break
          case 'runner':
            if (futureRunners) {
              odds = futureRunners[element.runnerId] || null
            } else {
              odds = null
            }
            break
        }
      } else {
        switch (element.mode) {
          case 'race':
            if (races) {
              odds = races[element.raceId] || null
            } else {
              odds = null
            }
            break
          case 'runner':
            if (runners) {
              odds = runners[element.runnerId] || null
            } else {
              odds = null
            }
            break
        }
      }

      element.currentOdds = odds
    })
  })
}
