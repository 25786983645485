import './dog.scss'

import CustomHTMLElement from 'html-element'
import FormattedTime from 'formatted-time'

import members from 'components/members'
import blackbooksApi from 'networking/api/members/blackbooks'
import SpriteSvg from 'elements/svg'

//////////

export default class BlackbookDog extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.dogId = this.getIntegerAttribute('data-dog-id')
    this.iconOnly = this.getBooleanAttribute('data-icon-only')

    this.setupElements()

    this.addEventListener('click', this.clicked)

    getBlackbookDog(this.dogId, (dog) => {
      this.blackbookDog = dog
    })
  }

  setupElements() {
    this.ribbonIcon = new SpriteSvg()

    this.appendChild(this.ribbonIcon)

    if (!this.iconOnly) {
      let contentWrapper = document.createElement('div')
      contentWrapper.classList.add('blackbook-dog__content')
      this.appendChild(contentWrapper)

      let textWrapper = document.createElement('div')
      contentWrapper.appendChild(textWrapper)

      this.titleElement = document.createElement('span')
      this.titleElement.classList.add('blackbook-dog__title')
      textWrapper.appendChild(this.titleElement)

      this.subtitleElement = document.createElement('span')
      this.subtitleElement.classList.add('blackbook-dog__subtitle')
      textWrapper.appendChild(this.subtitleElement)

      this.commentElement = document.createElement('div')
      this.commentElement.classList.add('blackbook-dog__comment')
      contentWrapper.appendChild(this.commentElement)
    }
  }

  ////////// Dog

  get blackbookDog() {
    return this._blackbookDog || null
  }
  set blackbookDog(blackbookDog) {
    this._blackbookDog = blackbookDog

    this.displayDog()
  }

  ////////// Display

  displayDog() {
    if (this.blackbookDog) {
      this.ribbonIcon.title = 'Dog Added to Blackbook'

      this.classList.add('blackbook-dog--active')
      this.classList.remove('blackbook-dog--add')

      this.ribbonIcon.iconName = 'icon_ribbon--fitted'

      if (!this.iconOnly) {
        this.titleElement.innerText = 'Blackbook:'

        if (this.blackbookDog.comment) {
          this.commentElement.innerText = this.blackbookDog.comment
          this.commentElement.classList.remove('blackbook-dog__comment--blank')
        } else {
          this.commentElement.innerText = 'No Comment'
          this.commentElement.classList.add('blackbook-dog__comment--blank')
        }

        this.subtitleElement.innerText = 'Dog Added'
        let timeElement = new FormattedTime({ format: 'date', date: this.blackbookDog.created_at })
        this.subtitleElement.appendChild(timeElement)
      }
    } else {
      this.ribbonIcon.title = 'Add Dog to Blackbook'

      this.classList.remove('blackbook-dog--active')

      this.ribbonIcon.iconName = 'icon_ribbon--fitted--outline'

      if (!this.iconOnly) {
        this.classList.add('blackbook-dog--add')
        this.titleElement.innerText = 'Add Dog to Blackbook'
        this.commentElement.innerText = ''
        this.subtitleElement.innerText = ''
      }
    }
  }

  ////////// Click

  clicked(event) {
    if (this.iconOnly || this.blackbookDog) {
      return
    }

    event.stopPropagation()

    blackbooksApi.addDog(this.dogId).then((response) => {
      let dog = null
      if (response.success) {
        dog = response.content.dog
      }
      let dogElements = document.querySelectorAll(`blackbook-dog[data-dog-id="${this.dogId}"]`)
      dogElements.forEach((dogElement) => { dogElement.blackbookDog = dog })
    })
  }

}
window.customElements.define('blackbook-dog', BlackbookDog)

////////// Updater

let blackbookDogUpdates = []
let blackbookDogsUpdateTimer = null
let retrievedBlackbookDogs = {}

function getBlackbookDog(dogId, callback) {
  let retrievedDog = retrievedBlackbookDogs[dogId]
  if (retrievedDog !== undefined) {
    setTimeout(() => callback(retrievedDog))
    return
  }

  if (blackbookDogsUpdateTimer) {
    window.clearTimeout(blackbookDogsUpdateTimer)
    blackbookDogsUpdateTimer = null
  }

  blackbookDogUpdates.push({ id: dogId, callback: callback })
  blackbookDogsUpdateTimer = window.setTimeout(getBlackbookDogs, 100)
}

function getBlackbookDogs() {
  if (!members.isLoggedIn) {
    blackbookDogUpdates.forEach((dogUpdate) => {
      setTimeout(() => dogUpdate.callback(null))
    })
    blackbookDogUpdates = []
    return
  }

  let dogIds = blackbookDogUpdates.map((d) => d.id)
  blackbooksApi.dogs(dogIds).then((response) => {
    let dogsResponse = {}
    if (response.success) {
      dogsResponse = response.content.dogs
    }

    blackbookDogUpdates.forEach((dogUpdate) => {
      let dog = dogsResponse[dogUpdate.id] || null
      retrievedBlackbookDogs[dogUpdate.id] = dog
      dogUpdate.callback(dog)
    })

    blackbookDogUpdates = []
  })
}
