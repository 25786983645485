import './scroll.scss';

let previousScrollPosition = window.pageYOffset;

document.addEventListener('scroll', calendarOnScroll);

function calendarOnScroll() {
  const calendarHeader = document.querySelector(".calendar-header__form");
  let currentScrollPosition = window.pageYOffset;

  if(calendarHeader) {
    if (previousScrollPosition > currentScrollPosition || currentScrollPosition <= 0) {
      calendarHeader.classList.remove('calendar-header--hide');
    } else {
      calendarHeader.classList.add('calendar-header--hide');
    }
  }

  previousScrollPosition = currentScrollPosition
}
