import './popover.scss'

import { sendRequest } from 'js-api'
import Popover from 'popover'

const TRANSITION_DURATION = 300

export default class VideoPopover extends Popover {

  _runSetup(options = {}) {
    this.videoPlayer = null
    options.removeOnHide = false

    super._runSetup(options)
  }

  set title(newValue) {}

  createElements() {
    this.background = document.createElement('a')
    this.background.classList.add('popover__background')
    this.background.addEventListener('click', (_event) => this.hide())
    this.appendChild(this.background)

    this.body = document.createElement('div')
    this.body.classList.add('popover__wrapper')
    this.appendChild(this.body)

    this.header = document.createElement('div')
    this.closeButton = document.createElement('a')
  }

  loadContent(url) {
    if (this.isPip) {
      this.exitPip()
      setTimeout(() => {
        this.pipDidEnd()
        this.loadContent(url)
      }, TRANSITION_DURATION + 100)

      return
    }

    this.videoPlayer = null

    this.body.innerHTML = '<div class="popover__header"><h1>Loading...</h1></div>'

    sendRequest('GET', url, { headers: { 'X-Application-Layout': 'video-popover' } }).then((response) => {
      this.displayContent(response.content)
      this.videoPlayer = this.querySelector('video-player')
      if (this.videoPlayer) {
        setTimeout(() => this.videoPlayer.start(), 1000)
      }
    })
  }

  hide() {
    if (this.videoPlayer) {
      this.videoPlayer.destroyVideoElement()
    }

    this.body.innerHTML = ''

    if (this.isPip) {
      this.isPip = false
      this.classList.remove('video-popover--pip')
    }

    super.hide()
  }

  ////////// Picture in Picture

  // Pip Events

  pipDidStart() {
    this.releaseScrolling()

    if (!this.isPip) {
      this.classList.remove('popover--active')
    }
  }
  pipDidEnd() {
    this.retainScrolling()
    setTimeout(() => this.classList.add('popover--active'))
  }

  // Custom Pip Handlers

  enterPip() {
    if (!this.videoPlayer) {
      return
    }

    this.isPip = true

    let wrapper = this.querySelector('.popover__wrapper')
    let placeholder = this.querySelector('.video-player-placeholder')
    let clientRect = this.videoPlayer.getBoundingClientRect()

    this.classList.add('video-popover--pip')
    this.videoPlayer.style.position = 'absolute'
    this.videoPlayer.style.top = `${clientRect.top}px`
    this.videoPlayer.style.left = `${clientRect.left}px`
    this.pipSizeFull()

    placeholder.style.display = 'block'
    wrapper.style.overflow = 'visible'

    setTimeout(() => {
      this.videoPlayer.style.transition = `all ${TRANSITION_DURATION / 1000}s`
      this.pipSizeNormal()
      this.pipMoveBottomRight()
    })

    this.setupPipEvents()
  }

  exitPip() {
    this.isPip = false

    setTimeout(() => {
      let wrapper = this.querySelector('.popover__wrapper')
      let placeholder = this.querySelector('.video-player-placeholder')

      this.pipSizeFull()

      let clientRect = placeholder.getBoundingClientRect()

      this.videoPlayer.style.top = `${clientRect.top}px`
      this.videoPlayer.style.left = `${clientRect.left}px`

      this.classList.remove('video-popover--pip')

      setTimeout(() => {
        this.videoPlayer.style.position = ''
        this.videoPlayer.style.top = ''
        this.videoPlayer.style.left = ''
        this.videoPlayer.style.width = ''
        this.videoPlayer.style.transition = ''

        placeholder.style.display = ''
        wrapper.style.overflow = ''
      }, TRANSITION_DURATION)
    })

    this.cancelPipEvents()
  }

  // Custom Pip Actions

  pipMoveTopLeft() {
    this.pipPosition = 'top-left'

    this.videoPlayer.style.top = 'var(--spacer-window)'
    this.videoPlayer.style.left = 'var(--spacer-window)'
  }
  pipMoveTopRight() {
    this.pipPosition = 'top-right'

    this.videoPlayer.style.top = 'var(--spacer-window)'
    this.videoPlayer.style.left = `calc(100% - (${this.pipSize}px + var(--spacer-window)))`
  }
  pipMoveBottomLeft() {
    this.pipPosition = 'bottom-left'

    let pipHeight = this.pipSize / 16 * 9
    this.videoPlayer.style.top = `calc(100% - (${pipHeight}px + var(--spacer-window)))`
    this.videoPlayer.style.left = 'var(--spacer-window)'
  }
  pipMoveBottomRight() {
    this.pipPosition = 'bottom-right'

    let pipHeight = this.pipSize / 16 * 9
    this.videoPlayer.style.top = `calc(100% - (${pipHeight}px + var(--spacer-window)))`
    this.videoPlayer.style.left = `calc(100% - (${this.pipSize}px + var(--spacer-window)))`
  }

  pipSizeFull() {
    this.pipSize = Math.min(this.clientWidth, 768)
    this.videoPlayer.style.width = `${this.pipSize}px`
  }
  pipSizeNormal() {
    this.pipSize = 200
    this.videoPlayer.style.width = `${this.pipSize}px`
  }

  // Grabbing

  setupPipEvents() {
    if (!this._grabStartListener) {
      this._grabStartListener = this.pipGrabStart.bind(this)
    }

    this.addEventListener('mousedown', this._grabStartListener)
    this.addEventListener('touchstart', this._grabStartListener)
  }
  cancelPipEvents() {
    this.removeEventListener('mousedown', this._grabStartListener)
    this.removeEventListener('touchstart', this._grabStartListener)
  }

  pipGrabStart(event) {
    let target = event.target
    while (target !== this) {
      if (target.tagName === 'A') {
        return
      }
      target = target.parentElement
    }

    this.addDocumentBoundEventListener('mousemove', this.pipGrabMove)
    this.addDocumentBoundEventListener('mouseup', this.pipGrabEnd)
    this.addDocumentBoundEventListener('touchmove', this.pipGrabMove)
    this.addDocumentBoundEventListener('touchend', this.pipGrabEnd)

    let offset = this.videoPlayer.getBoundingClientRect()
    this.videoPlayer.style.top = `${offset.y}px`
    this.videoPlayer.style.left = `${offset.x}px`

    let coordinate = (event.type === 'touchstart') ? event.touches[0] : event
    let grabX = coordinate.clientX
    let grabY = coordinate.clientY

    this.grabOffset = {
      x: (offset.x - grabX),
      y: (offset.y - grabY)
    }

    this.videoPlayer.style.transition = ''

    event.preventDefault()
    return false
  }

  pipGrabMove(event) {
    let coordinate = (event.type === 'touchmove') ? event.touches[0] : event
    let grabX = coordinate.clientX
    let grabY = coordinate.clientY

    this.videoPlayer.style.top = `${grabY + this.grabOffset.y}px`
    this.videoPlayer.style.left = `${grabX + this.grabOffset.x}px`
  }

  pipGrabEnd(event) {
    this.videoPlayer.style.transition = `all ${TRANSITION_DURATION / 1000}s`

    let offset = this.videoPlayer.getBoundingClientRect()
    let centerX = (offset.x + (offset.width / 2)) / window.innerWidth
    let centerY = (offset.y + (offset.height / 2)) / window.innerHeight

    if (centerY < 0.5) {
      if (centerX < 0.5) {
        this.pipMoveTopLeft()
      } else {
        this.pipMoveTopRight()
      }
    } else {
      if (centerX < 0.5) {
        this.pipMoveBottomLeft()
      } else {
        this.pipMoveBottomRight()
      }
    }

    this.removeDocumentBoundEventListener('mousemove', this.pipGrabMove)
    this.removeDocumentBoundEventListener('mouseup', this.pipGrabEnd)
    this.removeDocumentBoundEventListener('touchmove', this.pipGrabMove)
    this.removeDocumentBoundEventListener('touchend', this.pipGrabEnd)
  }

}
window.customElements.define('video-popover', VideoPopover)
