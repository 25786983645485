import './trainer.scss'

import CustomHTMLElement from 'html-element'
import FormattedTime from 'formatted-time'

import members from 'components/members'
import blackbooksApi from 'networking/api/members/blackbooks'
import SpriteSvg from 'elements/svg'

//////////

export default class BlackbookTrainer extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.trainerId = this.getIntegerAttribute('data-trainer-id')
    this.iconOnly = this.getBooleanAttribute('data-icon-only')

    this.setupElements()

    this.addEventListener('click', this.clicked)

    getBlackbookTrainer(this.trainerId, (trainer) => {
      this.blackbookTrainer = trainer
    })
  }

  setupElements() {
    this.ribbonIcon = new SpriteSvg()

    this.appendChild(this.ribbonIcon)

    if (!this.iconOnly) {
      let contentWrapper = document.createElement('div')
      contentWrapper.classList.add('blackbook-trainer__content')
      this.appendChild(contentWrapper)

      let textWrapper = document.createElement('div')
      contentWrapper.appendChild(textWrapper)

      this.titleElement = document.createElement('span')
      this.titleElement.classList.add('blackbook-trainer__title')
      textWrapper.appendChild(this.titleElement)

      this.subtitleElement = document.createElement('span')
      this.subtitleElement.classList.add('blackbook-trainer__subtitle')
      textWrapper.appendChild(this.subtitleElement)

      this.commentElement = document.createElement('div')
      this.commentElement.classList.add('blackbook-trainer__comment')
      contentWrapper.appendChild(this.commentElement)
    }
  }

  ////////// Trainer

  get blackbookTrainer() {
    return this._blackbookTrainer || null
  }
  set blackbookTrainer(blackbookTrainer) {
    this._blackbookTrainer = blackbookTrainer

    this.displayTrainer()
  }

  ////////// Display

  displayTrainer() {
    if (this.blackbookTrainer) {
      this.ribbonIcon.title = 'Trainer Added to Blackbook'

      this.classList.add('blackbook-trainer--active')
      this.classList.remove('blackbook-trainer--add')

      this.ribbonIcon.iconName = 'icon_ribbon--fitted'

      if (!this.iconOnly) {
        this.titleElement.innerText = 'Blackbook:'

        if (this.blackbookTrainer.comment) {
          this.commentElement.innerText = this.blackbookTrainer.comment
          this.commentElement.classList.remove('blackbook-trainer__comment--blank')
        } else {
          this.commentElement.innerText = 'No Comment'
          this.commentElement.classList.add('blackbook-trainer__comment--blank')
        }

        this.subtitleElement.innerText = 'Trainer Added'
        let timeElement = new FormattedTime({ format: 'date', date: this.blackbookTrainer.created_at })
        this.subtitleElement.appendChild(timeElement)
      }
    } else {
      this.ribbonIcon.title = 'Add Trainer to Blackbook'

      this.classList.remove('blackbook-trainer--active')

      this.ribbonIcon.iconName = 'icon_ribbon--fitted--outline'

      if (!this.iconOnly) {
        this.classList.add('blackbook-trainer--add')
        this.titleElement.innerText = 'Add Trainer to Blackbook'
        this.commentElement.innerText = ''
        this.subtitleElement.innerText = ''
      }
    }
  }

  ////////// Click

  clicked(event) {
    if (this.iconOnly || this.blackbookTrainer) {
      return
    }

    event.stopPropagation()

    blackbooksApi.addTrainer(this.trainerId).then((response) => {
      let trainer = null
      if (response.success) {
        trainer = response.content.trainer
      }
      let trainerElements = document.querySelectorAll(`blackbook-trainer[data-trainer-id="${this.trainerId}"]`)
      trainerElements.forEach((trainerElement) => { trainerElement.blackbookTrainer = trainer })
    })
  }

}
window.customElements.define('blackbook-trainer', BlackbookTrainer)

////////// Updater

let blackbookTrainerUpdates = []
let blackbookTrainersUpdateTimer = null
let retrievedBlackbookTrainers = {}

function getBlackbookTrainer(trainerId, callback) {
  let retrievedTrainer = retrievedBlackbookTrainers[trainerId]
  if (retrievedTrainer !== undefined) {
    setTimeout(() => callback(retrievedTrainer))
    return
  }

  if (blackbookTrainersUpdateTimer) {
    window.clearTimeout(blackbookTrainersUpdateTimer)
    blackbookTrainersUpdateTimer = null
  }

  blackbookTrainerUpdates.push({ id: trainerId, callback: callback })
  blackbookTrainersUpdateTimer = window.setTimeout(getBlackbookTrainers, 100)
}

function getBlackbookTrainers() {
  if (!members.isLoggedIn) {
    blackbookTrainerUpdates.forEach((trainerUpdate) => {
      setTimeout(() => trainerUpdate.callback(null))
    })
    blackbookTrainerUpdates = []
    return
  }

  let trainerIds = blackbookTrainerUpdates.map((d) => d.id)
  blackbooksApi.trainers(trainerIds).then((response) => {
    let trainersResponse = {}
    if (response.success) {
      trainersResponse = response.content.trainers
    }

    blackbookTrainerUpdates.forEach((trainerUpdate) => {
      let trainer = trainersResponse[trainerUpdate.id] || null
      retrievedBlackbookTrainers[trainerUpdate.id] = trainer
      trainerUpdate.callback(trainer)
    })

    blackbookTrainerUpdates = []
  })
}
