import { sendRequest } from 'js-api'

export default {
  source: (type, id, params = {}) => {
    let url = `api/videos/player/source/${type}`
    if (id) {
      url += `/${id}`
    }

    return sendRequest('GET', url, { params: params })
  }
}
