import './placement.scss'

import CustomHTMLElement from 'html-element'

import advertisingApi from 'networking/api/advertising'

const BACKGROUND_PLACEMENTS = [
  'floating_banner'
]

class AdPlacement extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.placementType = this.getAttribute('data-type')

    this.setupElements()
    this.addEventListener('click', this.clicked)

    updateAdsWhenReady()
  }

  ////////// Elements

  setupElements() {
    this.pictureElement = document.createElement('picture')
    this.appendChild(this.pictureElement)
  }

  ////////// Ad

  get currentAd() {
    return this._currentAd
  }

  set currentAd(ad) {
    this.placedAd = true
    this._currentAd = ad

    this.updateAdDisplay()
  }

  ////////// Display

  updateAdDisplay() {
    this.pictureElement.innerHTML = ''
    if (this.currentAd) {
      this.classList.add('ad-placement--active')

      let smallSource = document.createElement('source')
      smallSource.srcset = this.currentAd.small_image_url
      smallSource.media = '(max-width: 991px)'
      this.pictureElement.appendChild(smallSource)

      let imageElement = document.createElement('img')
      imageElement.src = this.currentAd.large_image_url
      this.pictureElement.appendChild(imageElement)

      if (BACKGROUND_PLACEMENTS.includes(this.placementType)) {
        this.style.backgroundColor = this.currentAd.color
      } else {
        this.style.backgroundColor = ''
      }
    } else {
      this.classList.remove('ad-placement--active')
    }
  }

  //////////  

  clicked(event) {
    event.stopPropagation()

    if (this.currentAd) {
      window.open(`/api/advertising/campaigns/${this.currentAd.campaign_id}/${this.placementType}/redirect`)
    }
  }

}
window.customElements.define('ad-placement', AdPlacement)

////////// Updater

let adsUpdateTimer = null
let updatingAds = false

function updateAdsWhenReady() {
  if (adsUpdateTimer) {
    window.clearTimeout(adsUpdateTimer)
    adsUpdateTimer = null
  }

  adsUpdateTimer = window.setTimeout(updateAds, 100)
}

function updateAds() {
  if (updatingAds) {
    return
  }
  updatingAds = true

  if (adsUpdateTimer) {
    adsUpdateTimer = null
  }

  let adElements = Array.from(document.querySelectorAll('ad-placement'))
  if (adElements.length === 0) { return }

  let placementTypes = adElements
    .filter((el) => !el.placedAd)
    .map((el) => el.placementType)
  advertisingApi.page(placementTypes).then((response) => {
    updatingAds = false

    let placements = response.content.placements
    adElements.forEach((element) => {
      let placement = placements[element.placementType] || null
      element.currentAd = placement
    })
  })
}
