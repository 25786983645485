import './ticker.scss'

import CustomHTMLElement from 'html-element'

class Ticker extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.itemsElement = this.querySelector('.ticker__items')
    this.href = this.getAttribute('href')

    this.displayNextItem()
  }

  _runConnected() {
    super._runConnected()

    this.startTickerTimer()
  }

  disconnectedCallback() {
    this.stopTickerTimer()
  }

  ////////// Timer

  startTickerTimer() {
    if (this.tickerInterval) {
      return
    }

    this.tickerInterval = window.setInterval(() => this.displayNextItem(), 5000)
  }

  stopTickerTimer() {
    if (!this.tickerInterval) {
      return
    }

    window.clearInterval(this.tickerInterval)
    this.tickerInterval = null
  }

  ////////// Items

  get activeItem() {
    return this.querySelector('.ticker__item--active')
  }

  displayNextItem() {
    let currentItem = this.activeItem
    let nextItem = null

    if (currentItem) {
      currentItem.classList.remove('ticker__item--active')
      nextItem = currentItem.nextSibling || this.itemsElement.firstChild
    } else {
      nextItem = this.itemsElement.firstChild
    }

    nextItem.classList.add('ticker__item--active')
  }

}

window.customElements.define('ticker-box', Ticker)
