import './odd_fluctuation.scss'
import CustomHTMLElement from 'html-element'
import Chart from 'chart.js/auto'
import runnerOddsApi from 'networking/api/runners/odds'

const ODDS_UPDATE_INTERVAL = 60 * 1000
export class RunnerOddFluctuationLow extends CustomHTMLElement {
  _runSetup() {
    super._runSetup()
    this.runnerId = this.getIntegerAttribute('data-runner-id')
    this.loaded = false
    this.sparklineData = []
    this.setupElements()
    updateOddsWhenReady()
  }
  setupElements() {
    this.avgPriceGraphElement = document.createElement('canvas')
    this.avgPriceGraphElement.classList.add(`runner-odd__fluctuation_${this.runnerId}`)
    this.appendChild(this.avgPriceGraphElement)
  }
  ////////// Odds
  get currentOdds() {
    return this._currentOdds || []
  }
  set currentOdds(odds) {
    this._currentOdds = odds
    this.displayAvgOddsGraph()
    this.loaded = true
  }
  ////////// Display
  displayAvgOddsGraph() {
    const odds = this.currentOdds
    let odd = null
    if (odds && odds.length > 0) {
      odd = odds[0]
      if (odd === null && odd.odds_fluctuation.average_prices === null) {
        this.avgPriceGraphElement.innerText = 'N/A'
      } else {
        let newSparklineData = odd.odds_fluctuation ? odd.odds_fluctuation.average_prices || [] : []
        if (JSON.stringify(this.sparklineData) !== JSON.stringify(newSparklineData)) {
          updateGraph(this.runnerId, newSparklineData)
          this.sparklineData = newSparklineData
        } else {
          if (!this.loaded) {
            updateGraph(this.runnerId, this.sparklineData)
          }
        }
      }
    }
  }
}

window.customElements.define('runner-odd-fluctuation-sparkline', RunnerOddFluctuationLow)
////////// Updater
let oddsUpdateTimer = null
let updatingOdds = false

function updateOddsWhenReady() {
  if (oddsUpdateTimer) {
    window.clearTimeout(oddsUpdateTimer)
    oddsUpdateTimer = null
  }
  oddsUpdateTimer = window.setTimeout(updateOdds, 100)
}

function updateOdds() {
  if (updatingOdds) {
    return
  }
  updatingOdds = true
  if (oddsUpdateTimer) {
    oddsUpdateTimer = null
  }
  let oddsElements = Array.from(document.querySelectorAll('runner-odd-fluctuation-sparkline'))
  if (oddsElements.length === 0) {
    return
  }
  let ids = {
    runnerIds: oddsElements.map((el) => el.runnerId)
  }
  runnerOddsApi.all(ids).then((response) => {
    oddsUpdateTimer = window.setTimeout(updateOdds, ODDS_UPDATE_INTERVAL)
    updatingOdds = false
    let runners = response.content.runner_odds
    oddsElements.forEach((element) => {
      let odds = runners[element.runnerId] || null
      element.currentOdds = odds
    })
  })
}

function updateGraph(runnerId, data) {
  // If the data has length 1, duplicate the element
  // so we have 2 points to connect in the graph.
  // We use JSON.parse / JSON.stringify to create a clone of
  // object so we don't modify the argument `data` which is
  // passed by value
  const clonedData = JSON.parse(JSON.stringify(data))
  if (clonedData && !clonedData['1']) {
    clonedData[1] = clonedData[0]
  }

  let ctx = document.getElementsByClassName(`runner-odd__fluctuation_${runnerId}`)

  let chartStatus = Chart.getChart(ctx) // <canvas> id
  if (chartStatus !== undefined) {
    chartStatus.destroy()
  }

  // eslint-disable-next-line no-unused-vars
  const chart = new Chart(ctx, {
    type: 'line',
    // The data for our dataset
    data: {
      datasets: [{
        backgroundColor: [
          'rgba(51, 153, 204, 0.2)'
        ],
        borderColor: [
          'rgba(51, 153, 204, 1)'
        ],
        borderWidth: 2,
        pointBorderColor: [
          'rgba(51, 153, 204, 0.5)'
        ],
        pointBackgroundColor: [
          'rgba(51, 153, 204, 0.5)'
        ],
        pointStyle: 'rectRot',
        data: clonedData,
        fill: true
      }]
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: false, // Hide legend
        tooltip: {
          usePointStyle: true,
          callbacks: {
            title: function(context) {
              return ''
            },
            labelPointStyle: function(context) {
              return {
                pointStyle: 'rectRot',
                rotation: 0
              }
            },
            label: function(context) {
              let label = context.dataset.label || ''

              if (label) {
                label += ': '
              }
              if (context.parsed.y !== null) {
                label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y)
              }
              return ' ' + label
            }
          }
        }
      },
      scales: {
        y: {
          display: false // Hide Y axis labels
        },
        x: {
          display: false // Hide X axis labels
        }
      },
      responsive: true
    }
  })
}
