export function wait(duration = 0) {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(), duration * 1000)
  })
}

export function every(duration, callback) {
  return window.setInterval(callback, duration * 1000)
}
export function cancelInterval(id) {
  window.clearInterval(id)
}
