import { sendRequest } from 'js-api'

export default {
  page: (placementTypes) => sendRequest('GET', 'api/advertising/page', {
    params: {
      placement_types: placementTypes
    }
  }),
  offers: () => sendRequest('GET', 'api/advertising/offers')
}
