import './odd_fluctuation.scss'

import CustomHTMLElement from 'html-element'

import runnerOddsApi from 'networking/api/runners/odds'

const ODDS_UPDATE_INTERVAL = 60 * 1000

export class RunnerOddFluctuation extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()
    this.runnerId = this.getIntegerAttribute('data-runner-id')
    this.oddsUpdateTimer = null
    this.updatingOdds = false
    this.setupElements()
    this.updateOddsWhenReady()
  }

  setupElements() {
    this.oddElement = document.createElement('span')
    this.oddElement.classList.add('runner-odd__fluctuation')
    this.appendChild(this.oddElement)
  }

  ////////// Odds
  get currentOdds() {
    return this._currentOdds || []
  }

  set currentOdds(odds) {
    this._currentOdds = odds
    this.displayOdds()
  }

  ////////// Display
  displayOdds() {
    const odds = this.currentOdds
    let odd = null
    if (odds && odds.length > 0) {
      odd = odds[0]
      const hasHighPrice = odd.odds_fluctuation != null && odd.odds_fluctuation.high_price != null
      const hasLowPrice = odd.odds_fluctuation != null && odd.odds_fluctuation.low_price != null
      const hasRequiredMetricPrice = (this.metric === 'high') ? hasHighPrice : hasLowPrice
      if (!hasRequiredMetricPrice) {
        this.displayOdd = null
        this.oddElement.innerText = 'N/A'
      } else {
        const requiredPrice = (this.metric === 'high') ? odd.odds_fluctuation.high_price : odd.odds_fluctuation.low_price
        if (this.displayOdd) {
          if (this.displayOdd < Number(requiredPrice)) {
            this.displayIncrease()
          } else if (Number(requiredPrice) < this.displayOdd) {
            this.displayDecrease()
          }
        }
        this.displayOdd = Number(requiredPrice)
        this.oddElement.innerText = Number(requiredPrice).toFixed(2)
      }
    } else {
      this.oddElement.innerText = 'N/A'
    }
  }

  displayDecrease() {
    this.classList.add('runner-odd--decreasing')
    setTimeout(() => this.classList.remove('runner-odd--decreasing'), 1000)
  }

  displayIncrease() {
    this.classList.add('runner-odd--increasing')
    setTimeout(() => this.classList.remove('runner-odd--increasing'), 1000)
  }

  updateOddsWhenReady() {
    if (this.oddsUpdateTimer) {
      window.clearTimeout(this.oddsUpdateTimer)
      this.oddsUpdateTimer = null
    }
    this.oddsUpdateTimer = window.setTimeout(this.updateOdds, 100)
  }

  updateOdds() {
    if (this.updatingOdds) {
      return
    }
    this.updatingOdds = true
    if (this.oddsUpdateTimer) {
      this.oddsUpdateTimer = null
    }
    let highOddsElements = Array.from(document.querySelectorAll('runner-odd-fluctuation-high'))
    if (highOddsElements.length === 0) {
      return
    }

    let lowOddsElements = Array.from(document.querySelectorAll('runner-odd-fluctuation-low'))
    if (lowOddsElements.length === 0) {
      return
    }

    let ids = {
      runnerIds: highOddsElements.map((el) => el.runnerId)
    }

    runnerOddsApi.all(ids).then((response) => {
      this.oddsUpdateTimer = window.setTimeout(this.updateOdds, ODDS_UPDATE_INTERVAL)
      this.updatingOdds = false
      let runners = response.content.runner_odds
      highOddsElements.forEach((element) => {
        let odds = runners[element.runnerId] || null
        element.currentOdds = odds
      })
      lowOddsElements.forEach((element) => {
        let odds = runners[element.runnerId] || null
        element.currentOdds = odds
      })
    })
  }
}

