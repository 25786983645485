import './table.scss'

import CustomHTMLElement from 'html-element'
import { sendRequest, createUrlQueryString, decodeUrlQueryString } from 'js-api'

import SpriteSvg from 'elements/svg'
import members from 'components/members'

export default class TableLoader extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.src = this.getAttribute('data-src')
    this.paramName = this.getAttribute('data-param-name')
    this.memberName = this.getAttribute('data-member-name')
    this.loadTable(this.src)
  }

  showLoading() {
    this.innerHTML = ''

    let loadingClass = document.createElement('div')
    loadingClass.classList.add('table-loader__loading')
    this.appendChild(loadingClass)

    let loadingText = document.createElement('span')
    loadingText.innerText = 'Loading...'
    loadingClass.appendChild(loadingText)

    let spinner = new SpriteSvg()
    spinner.iconName = 'icon_loading'
    loadingClass.appendChild(spinner)
  }

  async loadTable(url) {
    this.showLoading()

    let queryOptions = {}
    if (this.memberName && members.isLoggedIn) {
      let memberParams = members.cookie[this.memberName]
      Object.assign(queryOptions, memberParams)
    }

    if (this.paramName) {
      let query = window.location.search.replace(/^\?/, '')
      let queryParams = decodeUrlQueryString(query)
      let tableParams = queryParams[this.paramName]
      Object.assign(queryOptions, tableParams)
    }

    let urlSearch = url.split('?')[1]
    if (urlSearch) {
      let urlParams = decodeUrlQueryString(urlSearch)
      Object.assign(queryOptions, urlParams)
    }

    url = url.replace(/(\?.*|$)/, `?${createUrlQueryString(queryOptions)}`)

    let response = await sendRequest('GET', url, { headers: { 'X-Application-Layout': 'injection' } })
    this.displayContent(response.content)
  }

  displayContent(content) {
    this.innerHTML = ''

    let body = content.querySelector('body')
    let children = body.childNodes
    for (let i = children.length; i > 0; i--) {
      let child = children[i - 1]
      this.insertBefore(child, this.firstChild)
    }

    this.querySelectorAll('th a[href]')
      .forEach(element => {
        element.addEventListener('click', event => {
          let url = element.getAttribute('href')
          this.loadTable(url)

          event.preventDefault()
          event.stopPropagation()
          return false
        })
      })

    document.triggerEvent('table:load', this)
  }
}
window.customElements.define('table-loader', TableLoader)
