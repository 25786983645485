import './player.scss'

import Hls from 'hls.js'
import CustomHTMLElement from 'html-element'

import videoPlayerApi from 'networking/api/videos/player'
import CreateButton from 'elements/button'
import SpriteSvg from 'elements/svg'

import VideoControls from './controls'
import VideoPipControls from './pip_controls'

import analytics from 'components/analytics'

window.Hls = Hls

class VideoPlayer extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.sourceType = this.getAttribute('data-src-type')
    this.sourceId = this.getAttribute('data-src-id')
    this.sourceTitle = this.getAttribute('data-src-title')
    this.src = this.getAttribute('src')

    this.popover = this.closest('video-popover')
    this.createElements()
    this.didEnterInline()
  }

  _runConnected() {
    super._runConnected()

    if (!this._connectedStarted) {
      this._connectedStarted = true
      this.start()
    }

    this.addDocumentBoundEventListener('keydown', this.keyPressed)
    this.addWindowBoundEventListener('resize', this.documentResized)
  }
  disconnectedCallback() {
    this.removeDocumentBoundEventListener('keydown')
    this.removeWindowBoundEventListener('resize')
  }

  createElements() {
    this.controlsElement = this.createControlsElement()
    this.appendChild(this.controlsElement)

    this.pipControlsElement = this.createPipControlsElement()
  }

  start() {
    if (this.videoElement) { return }

    this.removeCoverMessage()
    this.classList.remove('video-player--fatal-error')
    this.fatalVideoError = false

    if (!this.videoContainer) {
      this.videoContainer = this.createVideoContainerElement()
      this.appendChild(this.videoContainer)
    }
    if (!this.videoElement) {
      this.videoElement = this.createVideoElement()
      this.videoContainer.appendChild(this.videoElement)
      this.activate()
    }

    this.attachConviva()

    this.startLoad()
  }

  async startLoad() {
    this.destroyHls()
    this.stopNative()

    this.hasLoaded = false
    this.isLoading = true
    this.loadingStateChanged()

    if (!this.src) {
      await this.loadSource()
    }
    if (!this.src) { return }

    this.adjustVideoSize()
    this.stateChanged('volume')

    if (this.supportsHls) {
      this.startHls()
    } else {
      this.startNative()
    }
  }

  get isLiveVideo() {
    let level = this.hlsLevel
    if (level && level.details) {
      return level.details.live
    } else if (!isFinite(this.duration)) {
      return true
    }

    return false
  }

  closePlayer() {
    if (this.popover) {
      this.popover.hide()
    }
  }

  ////////// Source

  async loadSource() {
    let params = {}
    if (this.prerollToken) {
      params.preroll_token = this.prerollToken
    }

    let response = await videoPlayerApi.source(this.sourceType, this.sourceId, params)
    let video = response.content.video
    if (video) {
      this.src = video.src

      if (video.preroll) {
        this.playingPreroll = true
        this.prerollToken = video.preroll.token
      } else {
        this.playingPreroll = false
      }
    }
    this.updateConvivaInfo()

    if (!this.src) {
      switch (response.status) {
        case 404:
          analytics.convivaVideo.reportPlaybackError(
            '404 - The video could not be found',
            Conviva.Constants.ErrorSeverity.FATAL
          )
          this.showFatalError('The video could not be found')
          break
        default:
          analytics.convivaVideo.reportPlaybackError(
            response.status+'-'+'There was an error loading the video',
            Conviva.Constants.ErrorSeverity.FATAL
          )
          this.showFatalError('There was an error loading the video')
          break
      }
    }
  }

  ////////// HLS
  reportPeakBitrateToOnHLSLevelSwitched (event, data) {
    if(this.hls.levels !== undefined
      && this.hls.levels[data.level]
      &&  this.hls.levels[data.level].bitrate !== undefined
    ){
    var bitrate = this.hls.levels[data.level].bitrate
    analytics.convivaVideo.reportPlaybackMetric(
      Conviva.Constants.Playback.BITRATE,
      bitrate / 1000
    )}
  }

  get hlsConfig() {
    return {
      enableWorker: true
    }
  }

  get supportsHls() {
    let parser = document.createElement('a')
    parser.href = this.src

    return !parser.pathname.endsWith('.mp4') && Hls.isSupported()
  }

  startHls() {
    this.hls = new Hls(this.hlsConfig)

    this.hls.on(Hls.Events.MEDIA_ATTACHED, () => this.hls.loadSource(this.src))
    this.hls.on(Hls.Events.MANIFEST_PARSED, () => this.resumePlayback())
    this.hls.on(Hls.Events.ERROR, (event, data) => this.hlsDidError(event, data))
    this.hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => this.reportPeakBitrateToOnHLSLevelSwitched(event, data))
    this.hls.once(Hls.Events.LEVEL_LOADED, (event, data) => this.reportPeakBitrateToOnHLSLevelSwitched(event, data))

    this.hls.attachMedia(this.videoElement)
  }

  destroyHls() {
    if (!this.hls) { return }

    this.hls.destroy()
  }

  get hlsLevel() {
    if (this.hls && this.hls.levels) {
      let levelIndex = this.hls.currentLevel
      return this.hls.levels[levelIndex]
    }

    return null
  }

  hlsDidError(_event, data) {
    const errorMessage = data.type + "-" + data.message
    const errorSeverity = data.fatal
      ? Conviva.Constants.ErrorSeverity.FATAL
      : Conviva.Constants.ErrorSeverity.WARNING
  
    if(data.type == Hls.ErrorTypes.OTHER_ERROR) {
      analytics.convivaVideo.reportPlaybackError(
        errorMessage,
        Conviva.Constants.ErrorSeverity.WARNING
      )
    } else {
      analytics.convivaVideo.reportPlaybackError(
        errorMessage,
        errorSeverity
      )
    }
 
    if (data.fatal === true) {
      switch (data.type) {
        case Hls.ErrorTypes.NETWORK_ERROR:
          console.error('fatal network error, trying to recover', data)
          switch (data.details) {
            case Hls.ErrorDetails.LEVEL_LOAD_ERROR:
              this.showFatalError('This stream is no longer available')
              break
            case Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
              this.showFatalError('Unable to load the stream')
              break
            default:
              this.hls.startLoad()
              break
          }
          return
        case Hls.ErrorTypes.MEDIA_ERROR:
          console.error('fatal media error, trying to recover', data)
          this.hlsHandlePlayerMediaError()
          return
        case Hls.ErrorTypes.OTHER_ERROR:
          if (data.details === Hls.ErrorDetails.INTERNAL_EXCEPTION) {
            console.error('internal hls error', data)
            return
          }
          break
      }
      console.error('fatal error, unable to recover', data)
      this.showFatalError('An error occurred loading the video')
    } else {
      console.warn(data)
    }
  }

  hlsHandlePlayerMediaError() {
    if (!this.hls) {
      this.showFatalError('An error occurred loading the video')
      return
    }

    let now = new Date()
    if (!this.recoverDecodingErrorDate || (now - this.recoverDecodingErrorDate) > 3000) {
      this.recoverDecodingErrorDate = now
      this.hls.recoverMediaError()
    } else if (!this.recoverSwapAudioCodecDate || (now - this.recoverSwapAudioCodecDate) > 3000) {
      this.recoverSwapAudioCodecDate = now
      this.hls.swapAudioCodec()
      this.hls.recoverMediaError()
    } else {
      this.showFatalError('An error occurred loading the video')
    }
  }

  ////////// Analytics

  get convivaInfo() {
    return {
      [Conviva.Constants.PLAYER_NAME]: 'web',
      'c3.app.version': window.config.appVersion,
      [Conviva.Constants.ASSET_NAME]: this.sourceTitle,
      [Conviva.Constants.STREAM_URL]: this.src,
      [Conviva.Constants.IS_LIVE]: this.isLiveVideo ? Conviva.Constants.StreamType.LIVE : Conviva.Constants.StreamType.VOD,
      [Conviva.Constants.DURATION]: this.duration,
      [Conviva.Constants.VIEWER_ID]: 'Public'
    }
  }

  attachConviva() {
    if (!analytics.hasConviva) { return }
    if (this.convivaAttached) { return }

    this.convivaAttached = true

    const deviceMetadata = {}
    // set the corresponding Conviva.Constants.DeviceType
    deviceMetadata[Conviva.Constants.DeviceMetadata.TYPE] = Conviva.Constants.DeviceType.DESKTOP
    deviceMetadata[Conviva.Constants.DeviceMetadata.CATEGORY] = Conviva.Constants.DeviceCategory.WEB
    Conviva.Analytics.setDeviceMetadata(deviceMetadata)

    analytics.convivaVideo.setPlayer(this.videoElement, {
      [Conviva.Constants.CONVIVA_MODULE]: window.ConvivaHtml5Module
    })
    analytics.convivaVideo.setPlayerInfo({
      [Conviva.Constants.FRAMEWORK_NAME]: 'html5',
      [Conviva.Constants.FRAMEWORK_VERSION]: '1.0.0'
    })
    analytics.convivaVideo.reportPlaybackRequested(this.convivaInfo)
  }
  detachConviva() {
    if (!analytics.hasConviva) { return }
    if (!this.convivaAttached) { return }

    this.convivaAttached = false
    analytics.convivaVideo.reportPlaybackEnded()
  }
  updateConvivaInfo() {
    if (!analytics.hasConviva) { return }

    analytics.convivaVideo.setContentInfo(this.convivaInfo)
  }

  ////////// Video Container

  createVideoContainerElement() {
    let containerElement = document.createElement('div')
    containerElement.classList.add('video-player__video-container')
    this.appendChild(containerElement)

    return containerElement
  }

  destroyVideoContainerElement() {
    if (!this.videoContainer) { return }

    this.videoContainer.remove()
    this.videoContainer = null
  }

  ////////// Video

  createVideoElement() {
    let videoElement = document.createElement('video')
    videoElement.setAttribute('playsinline', '')
    videoElement.setAttribute('autoplay', '')
    videoElement.setAttribute('x-webkit-airplay', 'deny')

    videoElement.addEventListener('loadedmetadata', this.videoDidLoadMetadata.bind(this))
    videoElement.addEventListener('loadeddata', this.videoDidLoadData.bind(this))

    videoElement.addEventListener('waiting', this.videoDidStartWaiting.bind(this))
    videoElement.addEventListener('playing', this.videoDidStartPlaying.bind(this))

    videoElement.addEventListener('error', this.videoDidError.bind(this))

    videoElement.addEventListener('contextmenu', this.videoContextMenu.bind(this))

    videoElement.addEventListener('play', this.videoDidPlay.bind(this))
    videoElement.addEventListener('pause', this.videoDidPause.bind(this))
    videoElement.addEventListener('suspend', this.videoDidSuspend.bind(this))

    videoElement.addEventListener('timeupdate', this.videoDidUpdateTime.bind(this))
    videoElement.addEventListener('volumechange', this.videoDidChangeVolume.bind(this))

    this.addDocumentBoundEventListener('webkitfullscreenchange', this.fullscreenChanged)
    this.addDocumentBoundEventListener('mozfullscreenchange', this.fullscreenChanged)
    this.addDocumentBoundEventListener('fullscreenchange', this.fullscreenChanged)

    videoElement.addEventListener('webkitpresentationmodechanged', this.webkitPresentationModeChanged.bind(this))
    videoElement.addEventListener('webkitplaybacktargetavailabilitychanged', this.airplayStateChanged.bind(this))

    if (window.EnterPictureInPictureEvent) {
      videoElement.addEventListener('enterpictureinpicture', this.nativeDidEnterPip.bind(this))
      videoElement.addEventListener('leavepictureinpicture', this.nativeDidExitPip.bind(this))
    }

    videoElement.addEventListener('click', this.videoClicked.bind(this))
    this.addEventListener('mousemove', this.mouseMoved.bind(this))
    videoElement.addEventListener('touchstart', this.videoClicked.bind(this))

    return videoElement
  }

  destroyVideoElement() {
    if (!this.videoElement) { return }

    this.detachConviva()

    this.isLoading = false
    this.loadingStateChanged()

    this.destroyHls()

    this.videoElement.remove()
    this.videoElement = undefined

    this.destroyVideoContainerElement()
  }

  startNative() {
    this.sourceElement = document.createElement('source')
    this.sourceElement.setAttribute('src', this.src)
    this.videoElement.appendChild(this.sourceElement)
    this.videoElement.load()
  }
  stopNative() {
    if (!this.sourceElement) { return }

    this.sourceElement.remove()
    this.sourceElement = null
  }

  videoClicked(event) {
    if (!this.isActive) {
      this.activate()
      this.resumePlayback()
      this.removeOverlayMessage()
    } else if (event.type === 'click') {
      if (this.usingPip) {
        return
      }
      this.togglePlayback()
    } else { // touchstart
      this.toggleOverlays()
    }

    event.preventDefault()
    return false
  }

  videoContextMenu(event) {
    event.preventDefault()
    return false
  }

  videoDidLoadMetadata(_event) {
    if (!this.hls) {
      this.resumePlayback()
    }

    this.updateConvivaInfo()
  }

  videoDidLoadData(_event) {
    this.hasLoaded = true
    this.stateChanged('presentation')
  }

  videoDidStartWaiting(event) {
    this.isLoading = true
    this.loadingStateChanged()
  }
  videoDidStartPlaying(event) {
    this.isLoading = false
    this.loadingStateChanged()
  }

  videoDidError(_event) {
    let mediaError = this.videoElement.error
    if (!mediaError) {
      analytics.convivaVideo.reportPlaybackError(
        'An error occurred loading the video',
        Conviva.Constants.ErrorSeverity.FATAL
      )
      this.showFatalError('An error occurred loading the video')
    
      return
    }
    switch (mediaError.code) {
      case mediaError.MEDIA_ERR_ABORTED:
        console.error('The video playback was aborted', mediaError)
        analytics.convivaVideo.reportPlaybackError(
            'The video playback was aborted'+'-'+mediaError,
            Conviva.Constants.ErrorSeverity.FATAL
        )
        this.showFatalError('An error occurred loading the video')
        break
      case mediaError.MEDIA_ERR_DECODE:
        console.error('The video playback was aborted due to a corruption problem or because the video used features your browser did not support', mediaError)
        analytics.convivaVideo.reportPlaybackError(
            'The video playback was aborted due to a corruption problem or because the video used features your browser did not support'+'-'+mediaError,
            Conviva.Constants.ErrorSeverity.FATAL
        )
        this.hlsHandlePlayerMediaError()
        break
      case mediaError.MEDIA_ERR_NETWORK:
        console.error('A network error caused the video download to fail part-way', mediaError)
        analytics.convivaVideo.reportPlaybackError(
            'A network error caused the video download to fail part-way'+'-'+mediaError,
            Conviva.Constants.ErrorSeverity.FATAL
        )
        this.showFatalError('An error occurred loading the video')
        break
      case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        console.error('The video could not be loaded, either because the server or network failed, or because the format is not supported', mediaError)
        analytics.convivaVideo.reportPlaybackError(
            'The video could not be loaded, either because the server or network failed, or because the format is not supported'+'-'+mediaError,
            Conviva.Constants.ErrorSeverity.FATAL
        )
        this.showFatalError('An error occurred loading the video')
        break
    }
  }

  videoDidPlay(_event) {
    this.stateChanged('playback')

    this.attachConviva()
  }
  videoDidPause(_event) {
    this.stateChanged('playback')
  }
  videoDidSuspend(_event) {
    this.stateChanged('playback')
  }

  videoDidUpdateTime(event) {
    if (!this.isLiveVideo && this.hasEnded) {
      this.pausePlayback()
      this.videoDidEnd(event)
    }

    this.stateChanged('progress')
  }
  videoDidEnd(_event) {
    if (!this.videoElement) { return }

    if (this.playingPreroll) {
      this.finishPreroll()
    }
  }

  videoDidChangeVolume(_event) {
    this.stateChanged('volume')
  }

  mouseMoved(_event) {
    this.showOverlays()
  }

  ////////// UI

  createControlsElement() {
    let controlsElement = new VideoControls(this)

    return controlsElement
  }

  createPipControlsElement() {
    let controlsElement = new VideoPipControls(this)

    return controlsElement
  }

  toggleOverlays() {
    if (this.showingOverlays) {
      this.hideOverlays()
    } else {
      this.showOverlays()
    }
  }

  showOverlays() {
    if (this.fatalVideoError || !this.active) {
      return
    }

    this.showingOverlays = true

    if (this.overlayTimeout) {
      window.clearTimeout(this.overlayTimeout)
      this.overlayTimeout = null
    }

    this.classList.add('video-player--overlays-active')

    if (this.isPlaying && !this.isLoading) {
      this.overlayTimeout = setTimeout(() => {
        this.hideOverlays()
      }, 3000)
    }
  }
  hideOverlays() {
    this.showingOverlays = false

    if (this.overlayTimeout) {
      window.clearTimeout(this.overlayTimeout)
      this.overlayTimeout = null
    }

    this.classList.remove('video-player--overlays-active')
  }

  // Messages

  showOverlayMessage(message) {
    let overlayMessage = document.createElement('div')
    overlayMessage.classList.add('video-player__overlay-message')

    let body = document.createElement('span')
    body.innerText = message
    overlayMessage.appendChild(body)

    if (this.overlayMessage) {
      this.overlayMessage.remove()
    }
    this.overlayMessage = overlayMessage
    this.appendChild(overlayMessage)
  }
  removeOverlayMessage() {
    if (!this.overlayMessage) { return }

    this.overlayMessage.remove()
    this.overlayMessage = undefined
  }

  showCoverMessage(message, options) {
    let coverMessage = document.createElement('div')
    coverMessage.classList.add('video-player__cover')
    coverMessage.classList.add('video-player__cover-message')

    if (options.icon) {
      let icon = new SpriteSvg()
      icon.iconName = options.icon
      coverMessage.appendChild(icon)
    }

    if (options.title) {
      let title = document.createElement('h2')
      title.innerText = options.title
      coverMessage.appendChild(title)
    }

    let body = document.createElement('p')
    body.innerText = message
    coverMessage.appendChild(body)

    if (options.action && options.actionTitle) {
      let button = CreateButton({
        text: options.actionTitle
      })
      button.addEventListener('click', options.action)
      coverMessage.appendChild(button)
    }

    if (this.coverMessage) {
      this.coverMessage.remove()
    }
    this.coverMessage = coverMessage
    this.appendChild(coverMessage)
  }
  removeCoverMessage() {
    if (!this.coverMessage) { return }

    this.coverMessage.remove()
    this.coverMessage = undefined
  }

  showFatalError(message) {
    this.classList.add('video-player--fatal-error')
    this.fatalVideoError = true
    this.destroyVideoElement()

    this.showCoverMessage(message, {
      title: 'Error',
      icon: 'video_error',
      actionTitle: 'Try Again',
      action: () => this.start()
    })

    this.hideOverlays()
  }

  ////////// Preroll

  finishPreroll() {
    this.src = null
    this.startLoad()
  }

  ////////// Keyboard

  keyPressed(event) {
    if (this.usingPip || !this.active) { return }
    if (event.metaKey || event.ctrlKey) { return }

    switch (event.keyCode) {
      case 27: // Escape
        this.closePlayer()
        break
      case 32: // Space
        this.togglePlayback()
        break
      case 37: // Left Arrow
        this.skipBackward()
        break
      case 38: // Up Arrow
        // volume up
        break
      case 39: // Right Arrow
        this.skipForward()
        break
      case 40: // Down Arrow
        // volume down
        break
      case 70: // F
        this.toggleFullscreen()
        break
      case 77: // M
        this.toggleSound()
        break
      case 80:
        this.togglePip()
        break
      default:
        // don't prevent default action for uncaptured action
        return
    }

    event.preventDefault()
    return false
  }

  ////////// State

  stateChanged(section) {
    let listeners = [this.controlsElement, this.pipControlsElement]
    listeners.forEach((listener) => {
      if (listener && typeof listener[`${section}StateChanged`] === 'function') {
        listener[`${section}StateChanged`]()
      }
    })
  }

  get isActive() {
    return this.active
  }

  activate() {
    if (this.active) { return }

    this.active = true
    this.enableSound()

    this.classList.add('video-player--active')
  }
  deactivate() {
    if (!this.active) { return }

    this.active = false
    this.disableSound()
    this.hideOverlays()

    this.classList.remove('video-player--active')
  }

  ////////// Loading

  loadingStateChanged() {
    this.showOverlays()
    this.stateChanged('loading')
  }

  ////////// Playback

  get isPlaying() {
    if (!this.videoElement) { return true }
    return !this.videoElement.paused
  }

  resumePlayback() {
    if (!this.videoElement || this.isPlaying) { return }

    // TODO
    // if (!this.isLive && this.hasEnded) {
    //   this.progress = 0
    // }

    let promise = this.videoElement.play()
    if (promise) {
      promise.catch((_error) => {
        this.deactivate()

        if (this.playingPreroll) {
          this.showOverlayMessage('click to play ad')
          return
        }

        this.videoElement.play().then(() => {
          this.showOverlayMessage('click to enable sound')
        }).catch((_error) => {
          if (this.isPlaying) {
            this.showOverlayMessage('click to enable sound')
          } else {
            this.showOverlayMessage('click to play')
          }
        })
      })
    } else {
      this.showOverlays()
    }

    this.stateChanged('playback')
  }

  pausePlayback() {
    if (!this.videoElement || !this.isPlaying) { return }

    this.videoElement.pause()

    this.showOverlays()
    this.stateChanged('playback')
  }

  togglePlayback() {
    if (this.isPlaying) {
      this.pausePlayback()
    } else {
      this.resumePlayback()
    }
  }

  ////////// Progress

  get elapsed() {
    if (!this.videoElement) { return 0 }
    return this.videoElement.currentTime
  }
  set elapsed(newValue) {
    this.videoElement.currentTime = newValue
  }

  get duration() {
    if (!this.videoElement) { return 0 }
    return this.videoElement.duration
  }

  get hasEnded() {
    return this.elapsed >= this.duration || this.elapsed < 0
  }

  get progress() {
    return this.elapsed / this.duration
  }
  set progress(newValue) {
    newValue = Math.min(Math.max(newValue, 0), 1)

    this.elapsed = newValue * this.duration
  }
  setProgressLive() {
    if (this.isLiveVideo) {
      let duration = this.duration
      if (isFinite(duration)) { this.elapsed = duration }
    }
  }

  skipBackward(time = 15) {
    if (this.isLive || this.playingPreroll) { return }

    this.elapsed -= time
  }
  skipForward(time = 15) {
    if (this.isLive || this.playingPreroll) { return }

    this.elapsed += time
  }

  ////////// Sound

  get hasSound() {
    if (!this.videoElement) { return false }
    return !this.videoElement.muted && this.volume > 0
  }
  enableSound() {
    this.volume = this.disabledVolume || 1
  }
  disableSound() {
    this.disabledVolume = this.volume
    this.volume = 0
  }

  toggleSound() {
    if (this.hasSound) {
      this.disableSound()
    } else {
      this.enableSound()
    }
  }

  get volume() {
    if (!this.videoElement) { return 0 }
    return this.videoElement.volume
  }
  set volume(newValue) {
    newValue = Math.min(Math.max(newValue, 0), 1)

    this.videoElement.volume = newValue
    this.videoElement.muted = (newValue === 0)

    this.stateChanged('volume')
  }

  ////////// Presentation

  updatePresentationMode(mode) {
    this.classList.remove(`video-player--presentation-${this.presentationMode}`)
    this.classList.add(`video-player--presentation-${mode}`)

    this.presentationMode = mode

    if (this.popover) {
      if (mode === 'pip') {
        this.popover.pipDidStart()
      } else {
        this.popover.pipDidEnd()
      }
    }

    this.adjustVideoSize()
    this.stateChanged('presentation')
  }

  didEnterInline() {
    this.updatePresentationMode('inline')
  }
  didEnterFullscreen() {
    this.updatePresentationMode('fullscreen')
  }
  didEnterPip() {
    this.updatePresentationMode('pip')
  }

  // Video Size

  documentResized(_event) {
    this.adjustVideoSize()
  }

  adjustVideoSize() {
    if (!this.videoElement) { return }
    if (this.playingPreroll) {
      this.videoElement.style.maxWidth = ''
      return
    }

    let dpi = 96
    if (navigator.platform.match(/iphone|ipod/i)) {
      dpi = 163
    } else if (navigator.platform.match(/ipad/i)) {
      dpi = 132
    } else if (navigator.platform.match(/macintel/i)) {
      dpi = 110
    } else if (navigator.platform.match(/android/i)) {
      dpi = 120
    }

    let maxPixelWidth = dpi * 9.6 // 9.6in width == 11in diag

    let percentage = (maxPixelWidth / window.outerWidth * 100)
    this.videoElement.style.maxWidth = `${percentage}vw`
  }

  // Full Screen

  get supportsFullscreen() {
    return document.fullscreenEnabled || this.videoElement.webkitRequestFullScreen || this.videoElement.webkitSupportsFullscreen
  }

  get usingFullscreeen() {
    return document.hasFullscreenElement() || document.fullScreen
  }

  enterFullscreen() {
    if (this.usingPip) { return }

    if (this.requestFullscreen) {
      this.requestFullscreen()
    } else {
      this.videoElement.requestFullscreen()
    }
  }
  exitFullscreen() {
    document.exitFullscreen()
  }

  toggleFullscreen() {
    if (this.usingFullscreeen) {
      this.exitFullscreen()
    } else {
      this.enterFullscreen()
    }
  }

  fullscreenChanged(event) {
    if (this.usingFullscreeen) {
      this.didEnterFullscreen()
    } else {
      this.didEnterInline()
    }
  }

  // Picture in Picture

  get supportsPip() {
    return !!this.popover
  }
  get usingPip() {
    return this.usingNativePip || this.usingSafariPip || this.usingCustomPip
  }

  get canEnterPip() {
    return this.supportsPip && this.presentationMode === 'inline'
  }

  enterPip() {
    if (this.usingFullscreeen) { return }

    if (this.supportsNativePip) {
      this.enterNativePip()
    } else if (this.supportsSafariPip) {
      this.enterSafariPip()
    } else {
      this.enterCustomPip()
    }
  }
  exitPip() {
    if (this.supportsNativePip) {
      this.exitNativePip()
    } else if (this.supportsSafariPip) {
      this.exitSafariPip()
    } else {
      this.exitCustomPip()
    }
  }

  togglePip() {
    if (this.usingPip) {
      this.exitPip()
    } else {
      this.enterPip()
    }
  }

  closePip() {
    this.popover.hide()
  }

  // Native

  get supportsNativePip() {
    return document.pictureInPictureEnabled && this.videoElement && typeof this.videoElement.requestPictureInPicture === 'function'
  }

  get usingNativePip() {
    return document.pictureInPictureElement === this.videoElement
  }

  enterNativePip() {
    this.videoElement.requestPictureInPicture().then(() => {
      this.nativeDidEnterPip()
    }).catch((error) => {
      console.error(error)
    })
  }
  exitNativePip() {
    document.exitPictureInPicture()
    this.didEnterInline()
  }

  nativeDidEnterPip(_event) {
    this.didEnterPip()
  }
  nativeDidExitPip(event) {
    this.didEnterInline()
  }

  // Safari

  get supportsSafariPip() {
    return this.videoElement && typeof this.videoElement.webkitSupportsPresentationMode === 'function' && this.videoElement.webkitSupportsPresentationMode('picture-in-picture')
  }

  get usingSafariPip() {
    return this.videoElement.webkitPresentationMode === 'picture-in-picture'
  }

  enterSafariPip() {
    this.videoElement.webkitSetPresentationMode('picture-in-picture')
  }
  exitSafariPip() {
    this.videoElement.webkitSetPresentationMode('inline')
  }

  webkitPresentationModeChanged(event) {
    switch (this.videoElement.webkitPresentationMode) {
      case 'inline':
        this.didEnterInline()
        break
      case 'picture-in-picture':
        this.didEnterPip()
        break
    }
  }

  // Custom

  get supportsCustomPip() {
    return !!this.popover
  }

  get usingCustomPip() {
    return this.popover.isPip
  }

  enterCustomPip() {
    this.popover.enterPip()
    this.didEnterPip()

    this.hideOverlays()
    this.controlsElement.remove()
    this.appendChild(this.pipControlsElement)
  }

  exitCustomPip() {
    this.popover.exitPip()
    this.didEnterInline()

    this.appendChild(this.controlsElement)
    this.pipControlsElement.remove()
  }

  ////////// Airplay

  get supportsAirplay() {
    return this.videoElement && typeof this.videoElement.webkitShowPlaybackTargetPicker !== 'undefined'
  }
  get canAirplay() {
    return this.airplayAvailable && this.videoElement.getAttribute('x-webkit-airplay') === 'allow'
  }

  showAirplayPicker() {
    this.videoElement.webkitShowPlaybackTargetPicker()
  }

  airplayStateChanged(event) {
    this.airplayAvailable = event.availability === 'available'

    this.stateChanged('airplay')
  }

  ////////// Google Cast

  // TODO

}
window.customElements.define('video-player', VideoPlayer)

