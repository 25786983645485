import { start as startUjs } from 'turbolinks-ujs'
startUjs()

import members from './members'

document.addEventListener('turboujs:formcomplete', (event) => {
  let xhr = event.detail[0]

  let location = document.createElement('a')
  location.href = xhr.responseURL

  if (location.pathname.startsWith('/member/')) {
    members.updateState()
  }
})
