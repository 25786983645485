import "./nav_bar.scss";

import CustomHTMLElement from "html-element";
import ScrollSnap from "scroll-snap";

let previousWindowWidth = window.innerWidth;

export default class NavBar extends CustomHTMLElement {
  _runSetup() {
    super._runSetup();

    this.toggleButton = this.querySelector(".nav-bar__toggle");
    this.closeButton = this.querySelector(".nav-bar__close");
    this.navigation = this.querySelector(".nav-bar__navigation");
    this.menu = this.querySelector(".nav-bar__menu");

    this.setupEvents();
  }

  disconnectedCallback() {
    this.removeWindowBoundEventListener("resize");
    this.removeDocumentBoundEventListener("scrolling-retainer:released");
  }

  ////////// Events

  setupEvents() {
    this.toggleButton.addEventListener("click", (_event) =>
      this.toggleNavigation()
    );

    this.closeButton.addEventListener("click", (_event) =>
      this.hideNavigation()
    );

    this.addWindowBoundEventListener("resize", (_event) =>
      this.windowResized()
    );
    this.addDocumentBoundEventListener(
      "scrolling-retainer:released",
      (_event) => this.hideNavigation()
    );
  }

  ////////// State

  toggleNavigation() {
    if (this.navigationActive) {
      this.hideNavigation();
    } else {
      this.showNavigation();
    }
  }

  showNavigation() {
    if (this.navigationActive) {
      return;
    }

    this.navigationActive = true;
    this.classList.add("nav-bar--active");

    this.menu.retainScrolling();
    this.toggleButton.classList.add("retained-scrolling");
  }
  hideNavigation() {
    if (!this.navigationActive) {
      return;
    }

    this.navigationActive = false;
    this.classList.remove("nav-bar--active");

    this.menu.releaseScrolling();
    this.toggleButton.classList.remove("retained-scrolling");
  }

  windowResized() {
    let width = window.innerWidth;

    if (width !== previousWindowWidth) {
      previousWindowWidth = width;

      this.hideNavigation();
      ScrollSnap.updateScrollSnap();
    }
  }
}

window.customElements.define("nav-bar", NavBar);
