import { sendRequest } from 'js-api'

export default {
  dogs: (dogIds) => sendRequest('GET', 'member/api/blackbook/dogs', {
    params: {
      dog_ids: dogIds
    }
  }),
  addDog: (dogId) => sendRequest('POST', 'member/api/blackbook/dogs', {
    params: {
      id: dogId
    }
  }),

  trainers: (trainerIds) => sendRequest('GET', 'member/api/blackbook/trainers', {
    params: {
      trainer_ids: trainerIds
    }
  }),
  addTrainer: (trainerId) => sendRequest('POST', 'member/api/blackbook/trainers', {
    params: {
      id: trainerId
    }
  }),

  parents: (dogIds) => sendRequest('GET', 'member/api/blackbook/parents', {
    params: {
      dog_ids: dogIds
    }
  }),
  addParent: (dogId) => sendRequest('POST', 'member/api/blackbook/parents', {
    params: {
      id: dogId
    }
  })
}
