import './map.scss'

import CustomHTMLElement from 'html-element'

//////////

class GoogleMap extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.markers = []

    this.markerInfo = this.getJsonAttribute('data-markers')
    if (!this.markerInfo) {
      this.latitude = this.getFloatAttribute('data-latitude')
      this.longitude = this.getFloatAttribute('data-longitude')
      this.title = this.getAttribute('data-title')
    }

    injectGoogleMaps()
  }

  _runConnected() {
    super._runConnected()

    this.setupMap()
  }

  //////////

  get mapOptions() {
    return {
      disableDefaultUI: true,
      gestureHandling: 'none'
    }
  }

  setupMap() {
    if (window.google === undefined || window.google.maps === undefined) { return }
    if (this.setup) { return }

    this.setup = true

    if (this.markerInfo) {
      let options = Object.assign(this.mapOptions, {})

      this.map = new window.google.maps.Map(this, options)
      this.markerInfo.forEach((marker) => this.addMarker(marker.title, marker.latitude, marker.longitude))

      let bounds = new window.google.maps.LatLngBounds()
      this.markers
        .map((marker) => marker.getPosition())
        .forEach((marker) => bounds.extend(marker))
      this.map.fitBounds(bounds)
    } else {
      let options = Object.assign(this.mapOptions, { center: { lat: this.latitude, lng: this.longitude }, zoom: 6 })

      this.map = new window.google.maps.Map(this, options)
      this.addMarker(this.title, this.latitude, this.longitude, { pin: true })
    }
  }

  addMarker(title, latitude, longitude, options = {}) {
    let location = { lat: latitude, lng: longitude }
    let data = { title: title, position: location }

    if (!options.pin) {
      data.icon = {
        path: 'M-5,0a5,5 0 1,0 10,0a5,5 0 1,0 -10,0',
        scale: 1,
        fillColor: '#ea4336',
        fillOpacity: 1,
        strokeColor: '#ffffff',
        strokeWeight: 1
      }
    }

    let marker = new window.google.maps.Marker(data)

    marker.setMap(this.map)

    this.markers.push(marker)
  }

}
window.customElements.define('google-map', GoogleMap)

//////////

let injectedMapsSDK = false

export function injectGoogleMaps() {
  if (injectedMapsSDK) { return }
  injectedMapsSDK = true

  let script = document.createElement('script')
  script.src = `https://maps.googleapis.com/maps/api/js?key=${window.config.googleMapsId}&callback=initGoogleMapsSDK`
  document.head.appendChild(script)
}

window.initGoogleMapsSDK = function() {
  document.querySelectorAll('google-map').forEach((map) => map.setupMap())
}
