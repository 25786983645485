import './video/player'
import VideoPopover from './video/popover'

let videoPopover = null

////////// Capture Links

document.addEventListener('turboujs:load', (_event) => {
  addVideoClickEvents(document)

  if (videoPopover) {
    document.body.appendChild(videoPopover)
  }
})
document.addEventListener('table:load', (event) => {
  addVideoClickEvents(event.detail)
})
document.addEventListener('accordion:load', (event) => {
  addVideoClickEvents(event.detail)
})
function addVideoClickEvents(element) {
  element.querySelectorAll('[data-turbolinks-action="video"]')
    .forEach((link) => link.addEventListener('click', videoLinkClicked))
}

function videoLinkClicked(event) {
  if (event.metaKey || event.ctrlKey) {
    return true
  }

  let url = this.href

  if (!videoPopover) {
    videoPopover = new VideoPopover()
    document.body.appendChild(videoPopover)
  }

  videoPopover.loadContent(url)
  videoPopover.show()

  event.preventDefault()
  return false
}

////////// Polyfill

if (typeof document.fullscreenEnabled === 'undefined') {
  document.fullscreenEnabled = (
    document.mozFullScreenEnabled ||
    document.webkitSupportsFullscreen ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled
  )
}

if (typeof document.fullScreen === 'undefined') {
  document.fullScreen = (
    document.mozFullScreen ||
    document.webkitIsFullScreen ||
    document.msFullscreenElement ||
    document.fullscreenElement
  )
}
document.hasFullscreenElement = function() {
  return document.fullscreenElement ||
  document.mozFullScreenElement ||
  document.webkitFullscreenElement ||
  document.msFullscreenElement
}

if (typeof document.exitFullscreen === 'undefined') {
  document.exitFullscreen = (
    document.mozCancelFullScreen ||
    document.webkitCancelFullScreen ||
    document.msExitFullscreen
  )
}

if (typeof HTMLElement.prototype.requestFullscreen === 'undefined') {
  HTMLElement.prototype.requestFullscreen = (
    HTMLElement.prototype.mozRequestFullScreen ||
    HTMLElement.prototype.webkitEnterFullscreen ||
    HTMLElement.prototype.webkitRequestFullScreen ||
    HTMLElement.prototype.msRequestFullscreen
  )
}


if (typeof HTMLVideoElement.prototype.requestFullscreen === 'undefined') {
  HTMLVideoElement.prototype.requestFullscreen = (
    HTMLVideoElement.prototype.mozRequestFullScreen ||
    HTMLVideoElement.prototype.webkitEnterFullscreen ||
    HTMLVideoElement.prototype.webkitRequestFullScreen ||
    HTMLVideoElement.prototype.msRequestFullscreen
  )
}
