import './pip_controls.scss'

import SpriteSvg from 'elements/svg'

import VideoOverlay from './overlay'

export default class VideoPipControls extends VideoOverlay {

  constructor(player) {
    super()

    this.player = player
    this.createElements()
  }

  ////////// Elements

  createElements() {
    this.playbackButton = this.createButton('video_play', (_event) => this.playbackClicked())
    this.pipButton = this.createButton('video_pip_exit', (_event) => this.pipClicked())
    this.closeButton = this.createButton('icon_cross', (_event) => this.closeClicked())

    this.progressElement = document.createElement('div')
    this.progressElement.classList.add('video-pip-controls__progress')
    this.appendChild(this.progressElement)
  }

  createButton(iconName, action) {
    let svg = new SpriteSvg()
    svg.iconName = iconName

    let button
    if (action) {
      button = document.createElement('a')
      button.addEventListener('click', action)
    } else {
      button = document.createElement('div')
    }

    button.classList.add('video-pip-controls__button')
    button.svg = svg
    button.appendChild(svg)

    button.setIcon = function(name) { this.svg.iconName = name }

    this.appendChild(button)

    return button
  }

  playbackClicked() {
    this.player.togglePlayback()
  }

  pipClicked() {
    this.player.exitPip()
  }

  closeClicked() {
    this.player.closePip()
  }

  ////////// State

  playbackStateChanged() {
    if (this.player.isPlaying) {
      this.playbackButton.setIcon('video_pause')
    } else {
      this.playbackButton.setIcon('video_play')
    }
  }

  progressStateChanged() {
    if (this.player.isLiveVideo) {
      this.progressElement.style.display = 'none'
    } else {
      this.progressElement.style.width = `${this.player.progress * 100}%`
    }
  }

}
window.customElements.define('video-pip-controls', VideoPipControls)
