import './odd_fluctuation.scss'

import { RunnerOddFluctuation } from './odds_fluctuation'

export class RunnerOddFluctuationLow extends RunnerOddFluctuation {
  _runSetup() {
    this.metric = 'low'
    super._runSetup()
  }
}

window.customElements.define('runner-odd-fluctuation-low', RunnerOddFluctuationLow)
