import CustomHTMLElement from 'html-element'

class ShareButton extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    if (typeof navigator.share !== 'function') {
      this.style.display = 'none'
    } else {
      this.shareTitle = this.getAttribute('data-share-title')
      this.shareUrl = this.getAttribute('data-share-url')

      this.addEventListener('click', () => {
        let shareData = {}

        if (this.shareTitle) {
          shareData.title = this.shareTitle
          shareData.text = this.shareTitle
        }

        if (this.shareUrl) {
          shareData.url = this.shareUrl
        } else {
          shareData.url = window.location.toString()
        }

        navigator.share(shareData)
      })
    }
  }
}
window.customElements.define('share-button', ShareButton)
