import { sendRequest } from 'js-api'

export default {
  all: (ids) => sendRequest('GET', 'api/runners/odds', {
    params: {
      runner_ids: ids.runnerIds,
      future_runner_ids: ids.futureRunnerIds,
      race_ids: ids.raceIds,
      future_race_ids: ids.futureRaceIds
    }
  })
}
