import { setup as apiSetup, sendRequest } from 'js-api'

import members from './members'

apiSetup({
  baseUrl: window.config.baseUrl,
  responseInterceptor: (response, requestParams) => {
    let requestOptions = requestParams[2]
    let resolver = requestOptions.resolve
    if (response.status === 401 && !requestOptions.ignoreUnauthorized) {
      members.showLogin(() => {
        if (members.isLoggedIn) {
          sendRequest(...requestParams).then((response) => {
            resolver(response)
          })
        } else {
          resolver.resolve(response)
        }
      })

      return { cancel: true }
    }
  }
})
