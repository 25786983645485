import './parent.scss'

import CustomHTMLElement from 'html-element'
import FormattedTime from 'formatted-time'

import members from 'components/members'
import blackbooksApi from 'networking/api/members/blackbooks'
import SpriteSvg from 'elements/svg'

//////////

export default class BlackbookParent extends CustomHTMLElement {

  _runSetup() {
    super._runSetup()

    this.dogId = this.getIntegerAttribute('data-dog-id')
    this.iconOnly = this.getBooleanAttribute('data-icon-only')

    this.setupElements()

    this.addEventListener('click', this.clicked)

    getBlackbookParent(this.dogId, (parent, isParent) => {
      if (parent || isParent) {
        this.blackbookParent = parent
      }
    })
  }

  setupElements() {
    this.ribbonIcon = new SpriteSvg()

    this.appendChild(this.ribbonIcon)

    if (!this.iconOnly) {
      let contentWrapper = document.createElement('div')
      contentWrapper.classList.add('blackbook-parent__content')
      this.appendChild(contentWrapper)

      let textWrapper = document.createElement('div')
      contentWrapper.appendChild(textWrapper)

      this.titleElement = document.createElement('span')
      this.titleElement.classList.add('blackbook-parent__title')
      textWrapper.appendChild(this.titleElement)

      this.subtitleElement = document.createElement('span')
      this.subtitleElement.classList.add('blackbook-parent__subtitle')
      textWrapper.appendChild(this.subtitleElement)

      this.commentElement = document.createElement('div')
      this.commentElement.classList.add('blackbook-parent__comment')
      contentWrapper.appendChild(this.commentElement)
    }
  }

  ////////// Parent

  get blackbookParent() {
    return this._blackbookParent || null
  }
  set blackbookParent(blackbookParent) {
    this._blackbookParent = blackbookParent

    this.displayParent()
  }

  ////////// Display

  displayParent() {
    if (this.blackbookParent) {
      this.ribbonIcon.title = 'Breeding Added to Blackbook'

      this.classList.add('blackbook-parent--active')
      this.classList.remove('blackbook-parent--add')

      this.ribbonIcon.iconName = 'icon_ribbon--fitted'

      if (!this.iconOnly) {
        this.titleElement.innerText = 'Blackbook:'

        if (this.blackbookParent.comment) {
          this.commentElement.innerText = this.blackbookParent.comment
          this.commentElement.classList.remove('blackbook-parent__comment--blank')
        } else {
          this.commentElement.innerText = 'No Comment'
          this.commentElement.classList.add('blackbook-parent__comment--blank')
        }

        this.subtitleElement.innerText = 'Breeding Added'
        let timeElement = new FormattedTime({ format: 'date', date: this.blackbookParent.created_at })
        this.subtitleElement.appendChild(timeElement)
      }
    } else {
      this.ribbonIcon.title = 'Add Breeding to Blackbook'

      this.classList.remove('blackbook-parent--active')

      this.ribbonIcon.iconName = 'icon_ribbon--fitted--outline'

      if (!this.iconOnly) {
        this.classList.add('blackbook-parent--add')
        this.titleElement.innerText = 'Add Breeding to Blackbook'
        this.commentElement.innerText = ''
        this.subtitleElement.innerText = ''
      }
    }
  }

  ////////// Click

  clicked(event) {
    if (this.iconOnly || this.blackbookParent) {
      return
    }

    event.stopPropagation()

    blackbooksApi.addParent(this.dogId).then((response) => {
      let parent = null
      if (response.success) {
        parent = response.content.parent
      }
      let parentElements = document.querySelectorAll(`blackbook-parent[data-dog-id="${this.dogId}"]`)
      parentElements.forEach((parentElement) => { parentElement.blackbookParent = parent })
    })
  }

}
window.customElements.define('blackbook-parent', BlackbookParent)

////////// Updater

let blackbookParentUpdates = []
let blackbookParentsUpdateTimer = null
let retrievedBlackbookParents = {}

function getBlackbookParent(dogId, callback) {
  let retrievedParent = retrievedBlackbookParents[dogId]
  if (retrievedParent !== undefined) {
    setTimeout(() => callback(retrievedParent))
    return
  }

  if (blackbookParentsUpdateTimer) {
    window.clearTimeout(blackbookParentsUpdateTimer)
    blackbookParentsUpdateTimer = null
  }

  blackbookParentUpdates.push({ id: dogId, callback: callback })
  blackbookParentsUpdateTimer = window.setTimeout(getBlackbookParents, 100)
}

function getBlackbookParents() {
  if (!members.isLoggedIn) {
    blackbookParentUpdates.forEach((parentUpdate) => {
      setTimeout(() => parentUpdate.callback(null))
    })
    blackbookParentUpdates = []
    return
  }

  let dogIds = blackbookParentUpdates.map((d) => d.id)
  blackbooksApi.parents(dogIds).then((response) => {
    let parentsResponse = {}
    let isParentsResponse = []
    if (response.success) {
      parentsResponse = response.content.parents
      isParentsResponse = response.content.is_parents
    }

    blackbookParentUpdates.forEach((parentUpdate) => {
      let parent = parentsResponse[parentUpdate.id] || null
      retrievedBlackbookParents[parentUpdate.id] = parent
      let isParent = isParentsResponse.includes(parentUpdate.id)
      parentUpdate.callback(parent, isParent)
    })

    blackbookParentUpdates = []
  })
}
