import './image_gallery.scss'

import CustomHTMLElement from 'html-element'

export default class ImageGallery extends CustomHTMLElement {
  _runSetup() {
    super._runSetup()

    this.addEventListener('click', this.showGallery.bind(this))

    this.imageElements = Array.from(this.querySelectorAll('[data-img-src]'))
    this.indexElementLength = this.imageElements.length
    this.imageUrls = this.imageElements.map((img) => img.getAttribute('data-img-src'))

    this.setupEvent()
  }

  setupEvent() {
    this.index = 0

    this.arrowLeftElement = document.querySelector('.arrow-left')
    this.arrowRightElement = document.querySelector('.arrow-right')

    this.arrowLeftElement.addEventListener('click', () => { this.arrowLeft(this.index) })
    this.arrowRightElement.addEventListener('click', () => { this.arrowRight(this.index) })
  }

  ////////  Show/Hide

  showGallery(event) {
    let clickedElement = document.elementFromPoint(event.x, event.y)
    let index = this.imageElements.indexOf(clickedElement) || 0

    if (!clickedElement.hasAttribute('data-img-src')) { return event.preventDefault() }

    if (this.indexElementLength > 1) {
      this.arrowLeftElement.classList.add('arrow-left--active')
      this.arrowRightElement.classList.add('arrow-right--active')
    }

    document.body.appendChild(this.galleryElement)
    this.chooseImage(index)
  }

  hideGallery(event) {
    let clickedElement = document.elementFromPoint(event.x, event.y)
    if (!clickedElement.classList.contains('image-gallery')) { return event.preventDefault() }

    this.arrowLeftElement.classList.remove('arrow-left--active')
    this.arrowRightElement.classList.remove('arrow-right--active')

    this.galleryElement.remove()
  }

  ////////  Left & Right Arrows

  arrowLeft(currentIndex) {
    this.index = currentIndex - 1

    if (this.index < 0) {
      this.index = this.indexElementLength - 1
    }

    return this.chooseImage(this.index)
  }

  arrowRight(currentIndex) {
    this.index = currentIndex + 1

    if (this.index > (this.indexElementLength - 1)) {
      this.index = 0
    }

    return this.chooseImage(this.index)
  }

  ///////////////////////

  chooseImage(index) {
    this.index = index
    this.imageElement.style.backgroundImage = `url('${this.imageUrls[index]}')`
  }

  get galleryElement() {
    if (!this._galleryElement) {
      this._galleryElement = document.createElement('div')
      this._galleryElement.classList.add('image-gallery')
      this._galleryElement.addEventListener('click', this.hideGallery.bind(this))

      this.imageElement = document.createElement('div')

      this._galleryElement.append(this.arrowLeftElement)
      this._galleryElement.appendChild(this.imageElement)
      this._galleryElement.append(this.arrowRightElement)
    }

    return this._galleryElement
  }
}

window.customElements.define('image-gallery', ImageGallery)
