import './offer.scss'

import CustomHTMLElement from 'html-element'

import advertisingApi from 'networking/api/advertising'

class AdOffers extends CustomHTMLElement {
  injectOffersResponse(response) {
    this.innerHTML = response.content.querySelector('body').innerHTML

    let listElements = this.querySelectorAll('li')
    listElements.forEach((listElement) => {
      listElement.addEventListener('click', (_event) => {
        if (listElement.classList.contains('list--offers--active')) {
          listElement.classList.remove('list--offers--active')
        } else {
          listElements.forEach((le) => le.classList.remove('list--offers--active'))
          listElement.classList.add('list--offers--active')
        }
      })
    })
  }
}
window.customElements.define('ad-offers', AdOffers)

////////// Updater

document.addEventListener('turbolinks:load', () => {
  let adOffers = document.querySelectorAll('ad-offers')
  if (adOffers.length > 0) {
    advertisingApi.offers().then((response) => {
      adOffers.forEach(offer => offer.injectOffersResponse(response))
    })
  }
})
