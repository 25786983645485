import './scrubber.scss'

export default class VideoControlsScrubber extends HTMLElement {

  constructor() {
    super()

    this.createElements()

    this.addEventListener('mousedown', this.grabDidStart.bind(this))
    this.addEventListener('touchstart', this.grabDidStart.bind(this))
  }

  //////////

  createElements() {
    let trackElement = document.createElement('div')
    trackElement.classList.add('video-controls-scrubber__track')
    this.appendChild(trackElement)

    this.thumbElement = document.createElement('div')
    this.thumbElement.classList.add('video-controls-scrubber__thumb')
    this.appendChild(this.thumbElement)
  }

  //////////

  get value() {
    return this._value
  }

  set value(newValue) {
    this._value = Math.max(Math.min(newValue, 1), 0)

    this.thumbElement.style.left = `${this._value * 100}%`
  }

  ////////// Events

  grabDidStart(event) {
    this.triggerEvent('beginChangingValue')

    this.addDocumentBoundEventListener('mousemove', this.grabDidMove)
    this.addDocumentBoundEventListener('touchmove', this.grabDidMove)
    this.addDocumentBoundEventListener('mouseup', this.grabDidEnd)
    this.addDocumentBoundEventListener('touchend', this.grabDidEnd)

    this.grabDidMove(event)

    event.preventDefault()
    return false
  }

  grabDidMove(event) {
    let clientX = null
    if (event.type.startsWith('mouse')) {
      clientX = event.clientX
    } else {
      clientX = event.touches[0].clientX
    }

    let progressX = this.getBoundingClientRect().left
    let width = this.clientWidth
    let position = clientX - progressX
    this.value = position / width

    this.triggerEvent('valueChanged', this.value)

    event.preventDefault()
    return false
  }

  grabDidEnd(_event) {
    this.triggerEvent('endChangingValue')

    this.removeDocumentBoundEventListener('mousemove')
    this.removeDocumentBoundEventListener('touchmove')
    this.removeDocumentBoundEventListener('mouseup')
    this.removeDocumentBoundEventListener('touchend')

    event.preventDefault()
    return false
  }

  //////////

  show() {
    this.classList.remove('video-controls-scrubber--hidden')
  }

  hide() {
    this.classList.add('video-controls-scrubber--hidden')
  }
}

window.customElements.define('video-controls-scrubber', VideoControlsScrubber)
