import { trackPageView as convivaEcoTrackPageView } from '@convivainc/conviva-js-appanalytics';

class AnalyticsController {

  ////////// Scripts

  injectGoogleAnalytics(id) {
    if (typeof id === 'string' && id.length > 0 && !document.querySelector('script[data-ga]')) {
      window.GoogleAnalyticsObject = 'ga'
      window.ga = window.ga || function() { (window.ga.q = window.ga.q || []).push(arguments) }
      window.ga.l = 1 * new Date()

      let script = document.createElement('script')
      script.async = 1
      script.src = 'https://www.google-analytics.com/analytics.js'
      script.setAttribute('data-ga', '')
      document.head.appendChild(script)
      window.ga('create', id, 'auto')
    }
  }

  injectGoogleTagManager(id) {
    if (typeof id === 'string' && id.length > 0 && !document.querySelector('script[data-gtag]')) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

      let script = document.createElement('script')
      script.async = true
      script.src = 'https://www.googletagmanager.com/gtm.js?id=' + id
      document.head.appendChild(script)
    }
  }

  ////////// Page Views

  trackPageView(loc) {
    if (typeof window.ga !== 'undefined') {
      window.ga('set', 'location', loc)
      window.ga('send', 'pageview')
    }
  }

  setupTurbolinksTracking() {
    document.addEventListener('turbolinks:load', this.turbolinksLoaded.bind(this))
  }
  turbolinksLoaded(event) {
    convivaEcoTrackPageView();
    this.trackPageView(event.data.url)
  }

  ////////// Conviva

  get hasConviva() {
    return typeof window.Conviva !== 'undefined'
  }

  setupConviva(id, debug) {
    let settings = {}
    if (debug) {
      settings[Conviva.Constants.LOG_LEVEL] = Conviva.Constants.LogLevel.DEBUG
      settings[Conviva.Constants.GATEWAY_URL] = 'https://thedogsau-test.testonly.conviva.com'
    }

    window.Conviva.Analytics.init(id, null, settings)
  }
  get convivaVideo() {
    if (!this._convivaVideo) {
      this._convivaVideo = window.Conviva.Analytics.buildVideoAnalytics()
    }

    return this._convivaVideo
  }

}

let analytics = new AnalyticsController()
export default analytics
