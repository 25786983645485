import './entries.scss'
import './form.scss'

import { sendRequest } from 'js-api'
import SpriteSvg from 'elements/svg'

document.addEventListener('turboujs:load', (_event) => {
  loadTableButtons(document)
})
document.addEventListener('accordion:load', (event) => {
  loadTableButtons(event.detail)
})

function loadTableButtons(content) {
  content.querySelectorAll('[data-runner-show-more]').forEach((button) => {
    button.addEventListener('click', async () => {
      let sprite = new SpriteSvg()
      sprite.id = 'form-loading-sprite'
      sprite.iconName = 'icon_loading'
      button.appendChild(sprite)

      let url = button.getAttribute('data-runner-show-more')
      let response = await sendRequest('GET', url, { headers: { 'X-Application-Layout': 'injection' } })

      let row = button.closest('tr')
      let rowParent = row.parentElement

      loadTableButtons(response.content)
      response.content.querySelectorAll('tr').forEach((rowElement) => {
        rowParent.insertBefore(rowElement, row)
      })

      row.remove()
    })
  })

  content.querySelectorAll('[data-runner-show-less]').forEach((button) => {
    button.addEventListener('click', () => {
      let table = button.closest('table')
      if (table.classList.contains('runner-form--show-less')) {
        table.classList.remove('runner-form--show-less')
        button.innerText = 'Show Less'
      } else {
        table.classList.add('runner-form--show-less')
        button.innerText = 'Show More'
      }
    })
  })
}
