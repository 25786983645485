import './odd_fluctuation.scss'

import { RunnerOddFluctuation } from './odds_fluctuation'

export class RunnerOddFluctuationHigh extends RunnerOddFluctuation {
  _runSetup() {
    this.metric = 'high'
    super._runSetup()
  }
}

window.customElements.define('runner-odd-fluctuation-high', RunnerOddFluctuationHigh)
